import { memo, useContext, useEffect, useState, useRef } from 'react';
import { useFormikContext } from 'formik';
import { FormikInputField } from './CommonTextField';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Divider, Stack, Typography } from '@mui/material';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import { DatePicker } from '@mui/x-date-pickers';
import { AttachFileIcon } from 'assets/SVG/ProfileSVG';
import { useUploadContext } from 'providers/UploadProviders/context';
import getImageFromS3 from 'api/imageService';
import { primary } from 'styles/themeColors';
import CloseIcon from '@mui/icons-material/Close';
import LoadingSpinner from 'components/LoadingSpinner';

const AchievementsForms = (props: any) => {
  const { user } = useContext(UserContext);
  const { uploadFile, deleteFile } = useUploadContext();
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const { values, setFieldError, setFieldValue, handleSubmit, validateForm } =
    useFormikContext<any>();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [fileUploaderData, setFileUploaderData] = useState<File>();
  const [selectedAttachmentFile, setSelectedAttachmentFile] = useState<string>();
  const openFileUploaderHandler = () => {
    fileUploadRef.current?.click();
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    setFileUploaderData(fileObj);
  };

  const handleDateChange = (date: any, dateText: string, keyboardInput?: string) => {
    let inputDate = '';
    if (keyboardInput) {
      try {
        inputDate = new Date(keyboardInput).toISOString();
      } catch (error) {
        setFieldError(dateText, 'Please enter a valid date in mm/dd/yyyy format');
      }
    }
    if (date && !keyboardInput) {
      inputDate = new Date(date).toISOString();
    }
    setFieldValue(dateText, inputDate);
  };
  const deleteFileHandler = async () => {
    setLoading(true);
    if (values?.achievements && values?.achievements[props.index]?.attachmentFile) {
      await (deleteFile && deleteFile(values?.achievements[props.index]?.attachmentFile));
    }
    setFieldValue(`achievements[${props.index}].attachmentFile`, '');
    setLoading(false);
  };
  const updateFieldValue = async () => {
    const userAchievement = user?.achievements ? [...user?.achievements] : [];
    if (props.submitActionType === 'add') {
      userAchievement.unshift({
        title: '',
        startDate: '',
        description: '',
        attachmentFile: ''
      });
    } else if (props.submitActionType === 'edit') {
      setLoading(true);
      const tempFile: string =
        user.achievements &&
        user.achievements[props.index].attachmentFile &&
        (await getImageFromS3(user.achievements[props.index].attachmentFile as string));
      setSelectedAttachmentFile(tempFile);
      setLoading(false);
    }
    setFieldValue('achievements', userAchievement);
  };
  useEffect(() => {
    updateFieldValue();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (values?.achievements && values?.achievements[props.index]?.attachmentFile) {
      await (deleteFile && deleteFile(values?.achievements[props.index]?.attachmentFile));
    }
    if (fileUploaderData) {
      setLoading(true);
      const fileName =
        fileUploaderData.type === 'application/pdf'
          ? await (uploadFile && uploadFile(fileUploaderData, 'achievement', 'pdf'))
          : await (uploadFile && uploadFile(fileUploaderData, 'achievement'));
      setFieldValue(`achievements[${props.index}].attachmentFile`, fileName.data.data);
      setLoading(false);
    }
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'achievements') {
        message = 'Please enter your complete Achievement details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };
  return (
    <>
      {!loading ? (
        <FormContainer name="achievements" onSubmit={onSubmit}>
          <Stack direction="column" justifyContent="space-around" alignItems="center">
            <FormikInputField
              name={`achievements[${props.index}].title`}
              placeholder="Name of Achievements"
            />
            <DatePicker
              views={['year', 'month']}
              value={
                typeof values?.achievements[props.index] !== 'undefined' &&
                values?.achievements[props.index].startDate
                  ? values?.achievements[props.index].startDate
                  : null
              }
              onChange={(date, input) => {
                handleDateChange(date, `achievements[${props.index}].startDate`, input);
              }}
              renderInput={(params) => (
                <FormikInputField name={`achievements[${props.index}].startDate`} {...params} />
              )}
            />
            <FormikInputField
              name={`achievements[${props.index}].description`}
              placeholder="Add Description"
              multiline={true}
              rows={5}
            />
          </Stack>
          <Stack direction="row">
            <PrimaryButton
              onClick={openFileUploaderHandler}
              sx={{ background: '#EEEEEE', minWidth: '40%', ml: 2, justifyContent: 'flex-start' }}>
              <AttachFileIcon sx={{ mx: 2 }} />
              <Typography
                component="span"
                sx={{ fontSize: 16, fontWeight: 400, color: '#999999', mx: 2 }}>
                Attach File
              </Typography>
            </PrimaryButton>
          </Stack>
          {typeof values.achievements[props.index] !== 'undefined' &&
          values.achievements[props.index].attachmentFile ? (
            <Stack direction="row" sx={{ my: 2 }}>
              <a
                style={{ fontSize: 14, fontWeight: 600 }}
                download={`achievement.${
                  values.achievements &&
                  values.achievements[props.index].attachmentFile.split('.').slice(-1)
                }`}
                href={
                  values.achievements &&
                  values?.achievements[props.index].attachmentFile.split('.').slice(-1) === 'pdf'
                    ? `data:application/pdf;base64,${selectedAttachmentFile}`
                    : `data:image/jpg;base64,${selectedAttachmentFile}`
                }>
                <Typography
                  component="span"
                  sx={{ fontSize: 12, color: primary.main, fontWeight: 600, mx: 2, my: 4 }}>
                  Attached file
                </Typography>
              </a>
              <Divider orientation="vertical" flexItem />
              <CloseIcon
                fontSize="small"
                color="error"
                sx={{ mx: 2 }}
                onClick={deleteFileHandler}
              />
            </Stack>
          ) : (
            <></>
          )}
          <input
            type="file"
            accept="image/*,.pdf"
            ref={fileUploadRef}
            style={{ display: 'none' }}
            onChange={fileChangeHandler}
          />
          {/* Submit Button  */}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '50px' }}>
            <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
              Submit
            </PrimaryButton>
          </Stack>
        </FormContainer>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default memo(AchievementsForms);
