import { memo, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormikInputField } from './CommonTextField';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Stack } from '@mui/material';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import { DatePicker } from '@mui/x-date-pickers';

const SpecialAbilityForm = (props: any) => {
  const { user } = useContext(UserContext);
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const { values, setFieldError, setFieldValue, handleSubmit, validateForm } =
    useFormikContext<any>();

  const handleDateChange = (date: any, dateText: string, keyboardInput?: string) => {
    let inputDate = '';
    if (keyboardInput) {
      try {
        inputDate = new Date(keyboardInput).toISOString();
      } catch (error) {
        setFieldError(dateText, 'Please enter a valid date in mm/dd/yyyy format');
      }
    }
    if (date && !keyboardInput) {
      inputDate = new Date(date).toISOString();
    }
    setFieldValue(dateText, inputDate);
  };
  const updateFieldValue = () => {
    if (props.submitActionType === 'add') {
      const userSpecialAbilities = user?.specialAbilities ? [...user?.specialAbilities] : [];
      userSpecialAbilities.unshift({
        title: '',
        startDate: '',
        description: ''
      });
      setFieldValue('specialAbilities', userSpecialAbilities);
    } else {
      setFieldValue('specialAbilities', user.specialAbilities);
    }
  };
  useEffect(() => {
    updateFieldValue();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'specialAbilities') {
        message = 'Please enter your complete Special Ability details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <>
      {/* Current Addresss */}

      <FormContainer name="specialAbilities" onSubmit={onSubmit}>
        <Stack direction="column" justifyContent="space-around" alignItems="center">
          <FormikInputField
            name={`specialAbilities[${props.index}].title`}
            placeholder="Add Special Ability"
          />
          <DatePicker
            label="Started in: mm/yyyy"
            views={['year', 'month']}
            value={
              typeof values?.specialAbilities[props.index] !== 'undefined' &&
              values?.specialAbilities[props.index].startDate
                ? values?.specialAbilities[props.index].startDate
                : null
            }
            onChange={(date, input) => {
              handleDateChange(date, `specialAbilities[${props.index}].startDate`, input);
            }}
            renderInput={(params) => (
              <FormikInputField name={`specialAbilities[${props.index}].startDate`} {...params} />
            )}
          />
          <FormikInputField
            name={`specialAbilities[${props.index}].description`}
            placeholder="Description"
            multiline={true}
            rows={5}
          />
        </Stack>
        {/* Submit Button  */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px' }}>
          <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
            Submit
          </PrimaryButton>
        </Stack>
      </FormContainer>
    </>
  );
};

export default memo(SpecialAbilityForm);
