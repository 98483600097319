/**
 * marketPlaceList, myAccountList, resourcesList , companyList
 * Handling all list and links of footer add or delete to udpate footer list
 */
//

export const marketPlaceList = [
  {
    menuLink: 'explore',
    menuText: "All Nft's"
  },
  {
    menuLink: 'explore?isPhygital=false',
    menuText: 'Digital Art'
  },
  {
    menuLink: 'explore?isPhygital=true',
    menuText: 'Phygital Art'
  }
];
export const resourcesList = [
  {
    menuLink: 'https://www.jumbish.co/help-centre',
    menuText: 'Help Center'
  },
  {
    menuLink: 'https://jumbish.co/blogs',
    menuText: 'Blogs'
  }
];
export const companyListInternal = [
  {
    menuLink: 'about',
    menuText: 'About'
  }
];
export const companyListExternal = [
  {
    menuLink: 'https://in.linkedin.com/company/jumbishcreations',
    menuText: 'Careers'
  }
];
