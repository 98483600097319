import React from 'react';
import { Route } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute';
import Art from 'pages/Art/Art';
import PageConstruction from 'pages/PageConstruction';
import ProfileFormProvider from 'providers/ProfileFormProvider/ProfileFormProvider';

//Always import using lazy loding
const Landing = React.lazy(() => import('../pages/Landing/Landing'));
const ProfileV2 = React.lazy(() => import('../pages/Profile/ProfileV2'));
const LogIn = React.lazy(() => import('../pages/LogIn/LogIn'));
const Explore = React.lazy(() => import('../pages/Explore/Explore'));

const About = React.lazy(() => import('pages/About/About'));
const Careers = React.lazy(() => import('pages/Careers/Careers'));
const DataProtectionGuidelines = React.lazy(() => import('pages/DataProtectionGuidelines'));
const TermsAndConditions = React.lazy(() => import('pages/TermsAndConditions/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy'));

const Resources = React.lazy(() => import('pages/Resources/Resources'));
const Newsletter = React.lazy(() => import('pages/Newsletter/Newsletter'));
const Interest = React.lazy(() => import('../pages/SignUp/Interest'));
const ActivateAccount = React.lazy(() => import('pages/SignUp/ActivateAccount'));
const ResetPassword = React.lazy(() => import('pages/SignUp/ResetPassword'));
const CreateEditArt = React.lazy(() => import('pages/CreateEditArt/CreateEditArt'));
const Wallet = React.lazy(() => import('pages/Wallet/Wallet'));
const MintArt = React.lazy(() => import('pages/MintArt/MintArt'));
const PaymentGatewayWindow = React.lazy(() => import('pages/Wallet/PaymentGatewayWindow'));

const AppRoutes = (
  <React.Fragment>
    {/* Header links  */}
    <Route path="/*" element={<Landing />} />
    <Route path="/home" element={<Landing />} />
    <Route path="/signup" element={<LogIn formState="signup" />} />
    <Route path="/login" element={<LogIn formState="login" />} />
    <Route path="/forgotpassword" element={<LogIn formState="reset" />} />
    <Route path="/auth/activate/:token" element={<ActivateAccount />} />
    <Route path="/auth/reset-password/:token" element={<ResetPassword />} />
    {/* Authenticated user routes - wrap around auth handler */}
    <Route
      path="/interest"
      element={
        <AuthRoute>
          <Interest />
        </AuthRoute>
      }
    />
    <Route
      path="/profile/:userId"
      element={
        <ProfileFormProvider mode="view">
          <ProfileV2 />
        </ProfileFormProvider>
      }
    />
    <Route
      path="/profile/"
      element={
        <AuthRoute>
          <ProfileFormProvider mode="edit">
            <ProfileV2 />
          </ProfileFormProvider>
        </AuthRoute>
      }
    />
    <Route
      path="/favorites"
      element={
        <AuthRoute>
          <PageConstruction />
        </AuthRoute>
      }
    />
    <Route
      path="/mycollection"
      element={
        <AuthRoute>
          <PageConstruction />
        </AuthRoute>
      }
    />
    <Route
      path="/settings"
      element={
        <AuthRoute>
          <PageConstruction />
        </AuthRoute>
      }
    />
    <Route
      path="/create-art"
      element={
        <AuthRoute>
          <CreateEditArt />
        </AuthRoute>
      }
    />
    <Route
      path="/edit-art/:id"
      element={
        <AuthRoute>
          <CreateEditArt />
        </AuthRoute>
      }
    />
    <Route
      path="/wallet"
      element={
        <AuthRoute>
          <Wallet />
        </AuthRoute>
      }
    />
    <Route
      path="/paymentGateways"
      element={
        <AuthRoute>
          <PaymentGatewayWindow />
        </AuthRoute>
      }
    />
    <Route
      path="/mint-art/:artId"
      element={
        <AuthRoute>
          <MintArt />
        </AuthRoute>
      }
    />
    {/* Art listing explore all  */}
    <Route path="/explore" element={<Explore />} />
    <Route path="/art/:artId" element={<Art />} />
    {/* Less Imp Links start */}
    <Route path="/resources" element={<PageConstruction />} />
    <Route path="/dataprotection" element={<PageConstruction />} />
    <Route path="/terms" element={<TermsAndConditions />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    {/* Resoures */}
    <Route path="/help-centre" element={<PageConstruction />} />
    <Route path="/partners" element={<PageConstruction />} />
    <Route path="/blogs" element={<PageConstruction />} />
    <Route path="/newsletter" element={<PageConstruction />} />
    {/* Company */}
    <Route path="/about" element={<About />} />
    <Route path="/careers" element={<PageConstruction />} />

    {/* Less Imp Links end  */}
  </React.Fragment>
);

export default AppRoutes;
