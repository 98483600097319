import { useState, memo, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormikInputField } from './CommonTextField';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Typography, Stack } from '@mui/material';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import { DatePicker } from '@mui/x-date-pickers';
import parseISO from 'date-fns/parseISO';
import { FormCheckBox } from 'components/CheckBox/CheckBox';
import theme from 'styles/styles';
import OrganisationAutoComplete from '../../../components/AutoComplete/OrganisationAutocomplete';

const EducationForm = (props: any) => {
  const { user } = useContext(UserContext);
  const {
    ToastService: { showToast }
  } = useSnackbarContext();
  const [selectedOrganisation, setSelectedOrganisation] = useState<any>(null);
  const { values, setFieldError, setFieldValue, handleSubmit, validateForm } =
    useFormikContext<any>();
  const [isCurrentlyPursuing, setIsCurrentlyPursuing] = useState(true);
  const handleDateChange = (date: any, dateText: string, keyboardInput?: string) => {
    let inputDate = '';
    if (keyboardInput) {
      try {
        inputDate = new Date(keyboardInput).toISOString();
      } catch (error) {
        setFieldError(dateText, 'Please enter a valid date in mm/dd/yyyy format');
      }
    }
    if (date && !keyboardInput) {
      inputDate = new Date(date).toISOString();
    }
    setFieldValue(dateText, inputDate);
  };
  const handleCurrentlyPursing = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // let oldValue = user.education
      const d = new Date();
      d.toISOString();
      setFieldValue(`educations.${props.index}.endDate`, d.toISOString());
    }
    setFieldValue(`educations.${props.index}.isCurrentlyPursuing`, event.target.checked);
    setIsCurrentlyPursuing(event.target.checked);
  };
  const updateFieldValue = async () => {
    const userEducations: any = user?.educations ? [...user.educations] : [];
    if (userEducations.length !== 0 && typeof userEducations[0].organisationId !== 'string')
      userEducations.map((edu: any) => {
        edu.organisationId = edu.organisationId._id;
      });
    if (props.submitActionType === 'add') {
      userEducations.unshift({
        organisationId: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: '',
        isCurrentlyPursuing: true
      });
    } else if (props.submitActionType === 'edit') {
      setSelectedOrganisation(userEducations[props.index].organisationId);
      setIsCurrentlyPursuing(userEducations[props.index].isCurrentlyPursuing);
    }
    setFieldValue('educations', userEducations);
  };

  useEffect(() => {
    updateFieldValue();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'educations') {
        message = 'Please enter your complete education details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <>
      <FormContainer name="educations" onSubmit={onSubmit}>
        <Stack direction="column" justifyContent="space-around" alignItems="center">
          <OrganisationAutoComplete
            setFieldString={`educations[${props.index}].organisationId`}
            selectedOrganisationId={selectedOrganisation}
            source="Education"
            placeholder="Name of School/University/Organisation"
          />
          <FormikInputField
            name={`educations[${props.index}].fieldOfStudy`}
            placeholder="Add Field Of Study"
          />
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
              <DatePicker
                label="Start Date: mm/yyyy"
                disableFuture
                views={['year', 'month']}
                value={
                  typeof values.educations[props.index] !== 'undefined' &&
                  values.educations[props.index].startDate
                    ? values.educations[props.index].startDate
                    : null
                }
                onChange={(date, input) => {
                  handleDateChange(date, `educations[${props.index}].startDate`, input);
                }}
                renderInput={(params) => (
                  <FormikInputField
                    style={{ width: '80%' }}
                    name={`educations[${props.index}].startDate`}
                    {...params}
                  />
                )}
              />
            </Stack>
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-end">
              <DatePicker
                label="End Date: mm/yyyy"
                disabled={isCurrentlyPursuing}
                disableFuture
                views={['year', 'month']}
                value={
                  typeof values.educations[props.index] !== 'undefined' &&
                  values.educations[props.index].endDate
                    ? values.educations[props.index].endDate
                    : null
                }
                onChange={(date, input) => {
                  handleDateChange(date, `educations[${props.index}].endDate`, input);
                }}
                renderInput={(params) => (
                  <FormikInputField
                    style={{ width: '80%' }}
                    name={`educations[${props.index}].endDate`}
                    {...params}
                  />
                )}
                minDate={
                  typeof values.educations[props.index] !== 'undefined'
                    ? parseISO(values.educations[props.index].startDate)
                    : null
                }
              />
              <Typography
                variant="subtitle2"
                style={{ color: theme.palette.text.secondary, width: '80%' }}>
                <FormCheckBox checked={isCurrentlyPursuing} onChange={handleCurrentlyPursing} />I am
                currently here!
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px' }}>
          <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
            Submit
          </PrimaryButton>
        </Stack>
      </FormContainer>
    </>
  );
};

export default memo(EducationForm);
