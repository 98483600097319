import { useState, memo, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormikInputField } from './CommonTextField';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Typography, Stack } from '@mui/material';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import { DatePicker } from '@mui/x-date-pickers';
import parseISO from 'date-fns/parseISO';
import { FormCheckBox } from 'components/CheckBox/CheckBox';
import theme from 'styles/styles';
import OrganisationAutoComplete from '../../../components/AutoComplete/OrganisationAutocomplete';

const WorkExperienceForm = (props: any) => {
  const { user } = useContext(UserContext);
  const {
    ToastService: { showToast }
  } = useSnackbarContext();
  const [selectedOrganisation, setSelectedOrganisation] = useState<any>(null);
  const { values, setFieldError, setFieldValue, handleSubmit, validateForm } =
    useFormikContext<any>();
  const [isCurrentlyMember, setIsCurrentlyMember] = useState(true);
  const handleDateChange = (date: any, dateText: string, keyboardInput?: string) => {
    let inputDate = '';
    if (keyboardInput) {
      try {
        inputDate = new Date(keyboardInput).toISOString();
      } catch (error) {
        setFieldError(dateText, 'Please enter a valid date in mm/dd/yyyy format');
      }
    }
    if (date && !keyboardInput) {
      inputDate = new Date(date).toISOString();
    }
    setFieldValue(dateText, inputDate);
  };
  const handleCurrentlyMember = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const d = new Date();
      d.toISOString();
      setFieldValue(`workExperiences.${props.index}.endDate`, d.toISOString());
    }
    setFieldValue(`workExperiences.${props.index}.isCurrentlyMember`, event.target.checked);
    setIsCurrentlyMember(event.target.checked);
  };
  const updateFieldValue = async () => {
    const userWorkExperiences: any = user?.workExperiences ? [...user.workExperiences] : [];
    if (
      userWorkExperiences.length !== 0 &&
      typeof userWorkExperiences[0].organisationId !== 'string'
    )
      userWorkExperiences.map((workExperiencesItem: any) => {
        workExperiencesItem.organisationId = workExperiencesItem.organisationId._id;
      });
    if (props.submitActionType === 'add') {
      userWorkExperiences.unshift({
        organisationId: '',
        title: '',
        startDate: '',
        endDate: '',
        isCurrentlyMember: true,
        description: ''
      });
    } else if (props.submitActionType === 'edit') {
      setSelectedOrganisation(userWorkExperiences[props.index].organisationId);
      setIsCurrentlyMember(userWorkExperiences[props.index].isCurrentlyMember);
    }
    setFieldValue('workExperiences', userWorkExperiences);
  };

  useEffect(() => {
    updateFieldValue();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'workExperiences') {
        message = 'Please enter your complete details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <>
      <FormContainer name="workExperiences" onSubmit={onSubmit}>
        <Stack direction="column" justifyContent="space-around" alignItems="center">
          <FormikInputField name={`workExperiences[${props.index}].title`} placeholder="Title" />
          <OrganisationAutoComplete
            setFieldString={`workExperiences[${props.index}].organisationId`}
            selectedOrganisationId={selectedOrganisation}
            source="employer"
            placeholder="Name of the company"
          />
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
              <DatePicker
                label="Start Date: mm/yyyy"
                disableFuture
                views={['year', 'month']}
                value={
                  typeof values.workExperiences[props.index] !== 'undefined' &&
                  values.workExperiences[props.index].startDate
                    ? values.workExperiences[props.index].startDate
                    : null
                }
                onChange={(date, input) => {
                  handleDateChange(date, `workExperiences[${props.index}].startDate`, input);
                }}
                renderInput={(params) => (
                  <FormikInputField
                    style={{ width: '80%' }}
                    name={`workExperiences[${props.index}].startDate`}
                    {...params}
                  />
                )}
              />
            </Stack>
            <Stack direction="column" justifyContent="flex-start" alignItems="flex-end">
              <DatePicker
                label="End Date: mm/yyyy"
                disabled={isCurrentlyMember}
                disableFuture
                views={['year', 'month']}
                value={
                  typeof values.workExperiences[props.index] !== 'undefined' &&
                  values.workExperiences[props.index].endDate
                    ? values.workExperiences[props.index].endDate
                    : null
                }
                onChange={(date, input) => {
                  handleDateChange(date, `workExperiences[${props.index}].endDate`, input);
                }}
                renderInput={(params) => (
                  <FormikInputField
                    style={{ width: '80%' }}
                    name={`workExperiences[${props.index}].endDate`}
                    {...params}
                  />
                )}
                minDate={
                  typeof values.workExperiences[props.index] !== 'undefined'
                    ? parseISO(values.workExperiences[props.index].startDate)
                    : null
                }
              />
              <Typography
                variant="subtitle2"
                style={{ color: theme.palette.text.secondary, width: '80%' }}>
                <FormCheckBox checked={isCurrentlyMember} onChange={handleCurrentlyMember} />I am
                currently here!
              </Typography>
            </Stack>
          </Stack>
          <FormikInputField
            name={`workExperiences[${props.index}].description`}
            placeholder="Add Description"
            multiline={true}
            rows={5}
          />
        </Stack>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px' }}>
          <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
            Submit
          </PrimaryButton>
        </Stack>
      </FormContainer>
    </>
  );
};

export default memo(WorkExperienceForm);
