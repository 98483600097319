export const userMenuList = [
  {
    value: 'profile',
    label: 'Profile',
    href: '/profile'
  },
  {
    value: 'wallet',
    label: 'Wallet',
    href: '/wallet'
  },
  {
    value: 'myArtworks',
    label: 'My Artworks',
    href: '/profile#myartworks'
  },
  {
    value: 'logout',
    label: 'Logout'
  }
];
