import { memo, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Stack } from '@mui/material';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import { DatePicker } from '@mui/x-date-pickers';
import { FormTextField } from 'components/TextFields/TextField';
import { parseISO } from 'date-fns';
import auctionService from 'api/auctionService';
import LoadingSpinner from 'components/LoadingSpinner';
import UserAutocomplete from 'components/AutoComplete/UserAutocomplete';
import OrganisationAutoComplete from '../../../components/AutoComplete/OrganisationAutocomplete';
const AddAuctionForm = (props: any) => {
  const { user } = useContext(UserContext);
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const { setFieldValue, setFieldError, handleSubmit, validateForm } = useFormikContext();
  const [loading, setLoading] = useState(false);
  const [selectedAuctioneer, setSelectedAuctioneer] = useState({
    artistName: '',
    artistId: '',
    artistEmail: ''
  });
  const [selectedCurator, setSelectedCurator] = useState({
    artistName: '',
    artistId: '',
    artistEmail: ''
  });
  const [selectedAuctionHouse, setSelectedAuctionHouse] = useState('');
  const [auctionData, setAuctionData] = useState({
    venue: '',
    startDate: '',
    endDate: ''
  });

  const updateFieldValue = () => {
    const selectedAuction: any = user.auctions && user.auctions[props.index];
    if (props.submitActionType === 'edit') {
      setAuctionData({
        venue: selectedAuction.venue,
        startDate: selectedAuction.startDate,
        endDate: selectedAuction.endDate
      });
      setSelectedAuctioneer({
        artistId: selectedAuction.auctioneer._id,
        artistName: `${selectedAuction.auctioneer.firstName} ${selectedAuction.auctioneer.lastName}`,
        artistEmail: ''
      });
      setSelectedCurator({
        artistId: selectedAuction.curator._id,
        artistName: `${selectedAuction.curator.firstName} ${selectedAuction.curator.lastName}`,
        artistEmail: ''
      });
      setSelectedAuctionHouse(selectedAuction.auctionHouse._id);
    }
  };
  useEffect(() => {
    updateFieldValue();
  }, []);
  const handleDateChange = (date: any, dateText: string, keyboardInput?: string) => {
    let inputDate = '';
    if (keyboardInput) {
      try {
        inputDate = new Date(keyboardInput).toISOString();
      } catch (error) {
        setFieldError(dateText, 'Please enter a valid date in mm/dd/yyyy format');
      }
    }
    if (date && !keyboardInput) {
      inputDate = new Date(date).toISOString();
    }
    // setFieldValue(dateText, inputDate);
    const tempAuctionData: any = { ...auctionData };
    tempAuctionData[`${dateText}`] = inputDate;
    setAuctionData(tempAuctionData);
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const currentUser: any = user;
    const currentUserAuctions = currentUser.auctions.map((auction: any) => auction._id);
    const form: any = await validateForm();
    let message = '';

    if (props.submitActionType === 'add') {
      setLoading(true);
      try {
        const createdAuction: any = await auctionService.createAuction({
          ...auctionData,
          auctioneer: selectedAuctioneer.artistId,
          curator: selectedCurator.artistId,
          auctionHouse: selectedAuctionHouse,
          createdBy: currentUser._id
        });
        setFieldValue('auctions', [createdAuction._id, ...currentUserAuctions]);
      } catch (_) {
        showToast(true, 'error', 'Something went Wrong');
      }
      setLoading(false);
    } else if (props.submitActionType === 'edit') {
      setLoading(true);
      try {
        await auctionService.updateAuction({
          ...auctionData,
          _id: currentUserAuctions[props.index],
          auctioneer: selectedAuctioneer.artistId,
          curator: selectedCurator.artistId,
          auctionHouse: selectedAuctionHouse
        });
        setFieldValue('auctions', [...currentUserAuctions]);
      } catch (_) {
        showToast(true, 'error', 'Something went Wrong');
      }
      setLoading(false);
    }
    if (form && Object.keys(form).length === 0) handleSubmit();

    for (const key in form) {
      if (key === 'auctions') {
        message = 'Please enter complete auction details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <>
      {!loading ? (
        <FormContainer name="auctions" onSubmit={onSubmit}>
          <Stack direction="column" justifyContent="space-around" alignItems="center">
            <UserAutocomplete
              selectedUserId={selectedAuctioneer}
              setData={setSelectedAuctioneer}
              placeholder="Auctioneer"
              source="Auctioneer"
            />
            <OrganisationAutoComplete
              setFieldString="auctionHouse"
              selectedOrganisationId={selectedAuctionHouse}
              source="Auction House"
              placeholder="Auction House"
              setData={setSelectedAuctionHouse}
            />
            <UserAutocomplete
              selectedUserId={selectedCurator}
              setData={setSelectedCurator}
              placeholder="Curator"
              source="Curator"
            />
            <FormTextField
              placeholder="Venue"
              value={auctionData.venue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAuctionData({ ...auctionData, venue: e.target.value })
              }
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                <DatePicker
                  label="Start Date: mm/yyyy"
                  disableFuture
                  views={['year', 'month']}
                  value={auctionData.startDate ? auctionData.startDate : null}
                  onChange={(date, input) => {
                    handleDateChange(date, 'startDate', input);
                  }}
                  renderInput={(params) => <FormTextField style={{ width: '80%' }} {...params} />}
                />
              </Stack>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-end">
                <DatePicker
                  label="End Date: mm/yyyy"
                  views={['year', 'month']}
                  value={auctionData.endDate ? auctionData.endDate : null}
                  //   typeof values.auction[props.index] !== 'undefined'
                  //     ? values.auction[props.index].endDate
                  //     : null
                  onChange={(date, input) => {
                    handleDateChange(date, `endDate`, input);
                  }}
                  renderInput={(params) => <FormTextField style={{ width: '80%' }} {...params} />}
                  minDate={parseISO(auctionData.startDate)}
                  //   typeof values.auction[props.index] !== 'undefined'
                  //     ? parseISO(values.auction[props.index].startDate)
                  //     : null
                />
              </Stack>
            </Stack>
          </Stack>
          {/* Submit Button  */}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '50px' }}>
            <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
              Submit
            </PrimaryButton>
          </Stack>
        </FormContainer>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default memo(AddAuctionForm);
