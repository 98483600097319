import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Typography, Stack } from '@mui/material';
import { formatDate } from 'utils/date.utils';
import { Link } from 'react-router-dom';
import ItemActivitiesLogo from 'assets/ItemActivitiesLogo.svg';

export default function DetailsTable(art: any) {
  return (
    <>
      <Typography sx={{ py: 5 }} variant="h3" component="h3" fontWeight="600">
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <img src={ItemActivitiesLogo} style={{ marginRight: '20px' }} />
          Item Activities
        </Stack>
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          mx: 'auto',
          borderRadius: '10px',
          backgroundColor: '#EEEEEE',
          boxShadow: '-12px -12px 24px #FFFFFF, 12px 12px 24px rgba(142, 209, 252, 0.25)'
        }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" sx={{ marginLeft: 10 }}>
                  Event
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" sx={{ marginLeft: 10 }}>
                  Price
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" sx={{ marginLeft: 10 }}>
                  From
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" sx={{ marginLeft: 10 }}>
                  To
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" sx={{ marginLeft: 10 }}>
                  Date
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={'nft-event'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <Typography variant="h4" sx={{ marginLeft: 10 }}>
                  Create Art
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" sx={{ marginLeft: 10 }}>
                  {art?.rate}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" sx={{ marginLeft: 10 }} color="primary.main">
                  Null Address
                </Typography>
              </TableCell>
              <TableCell>
                <Link to={`/profile/${art?.ownerId}`}>
                  <Typography variant="h4" sx={{ marginLeft: 10 }} color="primary.main">
                    {art?.ownerName}
                  </Typography>
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="h4" sx={{ marginLeft: 10 }}>
                  {formatDate('MMM yyyy', art?.createdTimestamp)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
