import axios from 'axios';
import { S3_IMAGE_SERVICE_URL, S3_IMAGE_SERVICE_URL_KEY } from 'constants/auth';

const axiosImageClient = axios.create({
  headers: {
    Accept: 'application/json',
    'x-api-key': S3_IMAGE_SERVICE_URL_KEY
  },

  baseURL: S3_IMAGE_SERVICE_URL
});

const getImageFromS3 = (imageKey: string) => {
  return axiosImageClient
    .get(`/${imageKey}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      throw new Error('Could not load art data.');
    });
};
export default getImageFromS3;
