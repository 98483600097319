import { memo, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormIconButton, PrimaryButton } from 'components/Buttons/Buttons';
import { FormContainer } from './FormContainer';
import { Box, Stack } from '@mui/material';
import UserAutocomplete from 'components/AutoComplete/UserAutocomplete';
import { AddOutlined } from '@mui/icons-material';
import UserContext from 'context/UserContext';
import { useSnackbarContext } from 'components/Snackbar/context';
import ChipsArray from 'components/ChipArray/ChipArray';

const MenteeForm = (props: any) => {
  const { user } = useContext(UserContext);
  const [selectedMentees, setSelectedMentees] = useState<any[]>([]);
  const [autocompleteData, setAutocompleteData] = useState<{
    artistName: string;
    artistId: string;
  }>({
    artistName: '',
    artistId: ''
  });
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const { values, setFieldValue, handleSubmit, validateForm } = useFormikContext<any>();

  const updateFieldValue = () => {
    const userMentees: string[] = user?.mentees ? [...user.mentees] : [];
    setSelectedMentees(userMentees);
    if (userMentees.length !== 0 && typeof userMentees[0] !== 'string')
      userMentees.map((userMentee: any) => {
        userMentee = userMentee._id;
      });
    setFieldValue('mentees', userMentees);
  };

  useEffect(() => {
    updateFieldValue();
  }, []);

  const getFirstName = (fullName?: string) => {
    const lastIndex = fullName?.lastIndexOf(' ');
    return lastIndex ? fullName?.slice(0, lastIndex) : '';
  };

  const getLastName = (fullName?: string) => {
    const lastIndex = fullName?.lastIndexOf(' ');
    return lastIndex ? fullName?.slice(lastIndex + 1) : '';
  };

  const addMenteeHandler = () => {
    const newSelectedMentees = selectedMentees;
    newSelectedMentees.push({
      _id: autocompleteData.artistId,
      firstName: getFirstName(autocompleteData.artistName),
      lastName: getLastName(autocompleteData.artistName)
    });

    const userMentees: string[] = [];
    if (newSelectedMentees.length !== 0) {
      newSelectedMentees.map((userMentee: any) => {
        userMentees.push(userMentee._id);
      });
    }
    setFieldValue('mentees', userMentees);
    setSelectedMentees(newSelectedMentees);
    setAutocompleteData({ artistId: '', artistName: '' });
  };

  const chipArrayDataChange = (selMentees: any[]) => {
    const newSelectedMentees = selMentees.map((userMentee: any) => userMentee.key);
    setFieldValue('mentees', newSelectedMentees);

    setSelectedMentees(
      selMentees.map((userMentee: any) => ({
        _id: userMentee.key,
        firstName: getFirstName(userMentee.label),
        lastName: getLastName(userMentee.label)
      }))
    );
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'mentees') {
        message = 'Please enter your complete Address details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <Stack flexDirection="column" width="100%">
      <Box>
        <ChipsArray
          data={
            selectedMentees.map((m: any) => ({
              key: m._id,
              label: m.firstName + ' ' + m.lastName
            })) || []
          }
          setData={chipArrayDataChange}
        />
      </Box>

      <FormContainer name="mentees" onSubmit={onSubmit}>
        <Stack direction="row" alignItems="center">
          <UserAutocomplete
            selectedUserId={autocompleteData}
            setData={setAutocompleteData}
            placeholder="Add mentee name"
            source="mentee"
          />
          <FormIconButton>
            <AddOutlined sx={{ color: '#7C7C7C' }} onClick={addMenteeHandler} />
          </FormIconButton>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px' }}>
          <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
            Submit
          </PrimaryButton>
        </Stack>
      </FormContainer>
    </Stack>
  );
};

export default memo(MenteeForm);
