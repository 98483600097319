import { Organisation } from 'pages/Profile/interfaces/organisation.interface';
import { default as axios } from './Instance';

const createOrganisationProfile: (data: any, source: string) => Promise<Organisation> = async (
  data: any,
  source: string
) => {
  try {
    const response = await axios.post(`/organisation/createProfile?source=${source}`, data);
    return response.data.data;
  } catch (error) {
    throw new Error('error on user profile update');
  }
};

export default {
  createOrganisationProfile
};
