import { memo, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Stack } from '@mui/material';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import { DatePicker } from '@mui/x-date-pickers';
import { FormTextField } from 'components/TextFields/TextField';
import { parseISO } from 'date-fns';
import eventService from 'api/eventService';
import LoadingSpinner from 'components/LoadingSpinner';
const AddEventForm = (props: any) => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [eventData, setEventData] = useState({
    eventName: '',
    venue: '',
    startDate: '',
    endDate: '',
    description: ''
  });
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const { setFieldValue, setFieldError, handleSubmit, validateForm } = useFormikContext();

  const updateFieldValue = () => {
    const selectedEvent: any = user.events && user.events[props.index];
    if (props.submitActionType === 'edit') {
      setEventData({
        eventName: selectedEvent.eventName,
        venue: selectedEvent.venue,
        startDate: selectedEvent.startDate,
        endDate: selectedEvent.endDate,
        description: selectedEvent.description
      });
    }
  };
  useEffect(() => {
    updateFieldValue();
  }, []);
  const handleDateChange = (date: any, dateText: string, keyboardInput?: string) => {
    let inputDate = '';
    if (keyboardInput) {
      try {
        inputDate = new Date(keyboardInput).toISOString();
      } catch (error) {
        setFieldError(dateText, 'Please enter a valid date in mm/dd/yyyy format');
      }
    }
    if (date && !keyboardInput) {
      inputDate = new Date(date).toISOString();
    }
    // setFieldValue(dateText, inputDate);
    const tempEventData: any = { ...eventData };
    tempEventData[`${dateText}`] = inputDate;
    setEventData(tempEventData);
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const currentUser: any = user;
    const currentUserEvents = currentUser.events.map((event: any) => event._id);
    const form: any = await validateForm();
    let message = '';

    if (props.submitActionType === 'add') {
      setLoading(true);
      try {
        const createdEvent: any = await eventService.createEvent({
          ...eventData,
          createdBy: currentUser._id
        });
        setFieldValue('events', [createdEvent._id, ...currentUserEvents]);
      } catch (_) {
        showToast(true, 'error', 'Something went Wrong');
      }
      setLoading(false);
    } else if (props.submitActionType === 'edit') {
      setLoading(true);
      try {
        await eventService.updateEvent({ _id: currentUserEvents[props.index], ...eventData });
        setFieldValue('events', [...currentUserEvents]);
      } catch (_) {
        showToast(true, 'error', 'Something went Wrong');
      }
      setLoading(false);
    }
    if (form && Object.keys(form).length === 0) handleSubmit();

    for (const key in form) {
      if (key === 'events') {
        message = 'Please enter complete event details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <>
      {!loading ? (
        <FormContainer name="events" onSubmit={onSubmit}>
          <Stack direction="column" justifyContent="space-around" alignItems="center">
            <FormTextField
              placeholder="Event Name"
              value={eventData.eventName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEventData({ ...eventData, eventName: e.target.value })
              }
            />
            <FormTextField
              placeholder="Venue"
              value={eventData.venue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEventData({ ...eventData, venue: e.target.value })
              }
            />
            <FormTextField
              placeholder="Add Description"
              multiline={true}
              value={eventData.description}
              rows={5}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEventData({ ...eventData, description: e.target.value })
              }
            />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
                <DatePicker
                  label="Start Date: mm/yyyy"
                  disableFuture
                  views={['year', 'month']}
                  value={eventData.startDate ? eventData.startDate : null}
                  onChange={(date, input) => {
                    handleDateChange(date, 'startDate', input);
                  }}
                  renderInput={(params) => <FormTextField style={{ width: '80%' }} {...params} />}
                />
              </Stack>
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-end">
                <DatePicker
                  label="End Date: mm/yyyy"
                  views={['year', 'month']}
                  value={eventData.endDate ? eventData.endDate : null}
                  //   typeof values.event[props.index] !== 'undefined'
                  //     ? values.event[props.index].endDate
                  //     : null
                  onChange={(date, input) => {
                    handleDateChange(date, `endDate`, input);
                  }}
                  renderInput={(params) => <FormTextField style={{ width: '80%' }} {...params} />}
                  minDate={parseISO(eventData.startDate)}
                  //   typeof values.event[props.index] !== 'undefined'
                  //     ? parseISO(values.event[props.index].startDate)
                  //     : null
                />
              </Stack>
            </Stack>
          </Stack>
          {/* Submit Button  */}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: '50px' }}>
            <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
              Submit
            </PrimaryButton>
          </Stack>
        </FormContainer>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default memo(AddEventForm);
