import {
  Autocomplete,
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import axiosClient from 'api/Instance';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useMemo, useState } from 'react';
import { Organisation } from '../../pages/Profile/interfaces/organisation.interface';
import { FormikInputField } from '../../pages/Profile/ProfileForms/CommonTextField';
import { FormDialog } from 'components/Modal/Modal';
import { FormIconButton, PrimaryButton } from 'components/Buttons/Buttons';
import { FormTextField } from 'components/TextFields/TextField';
import organisationService from 'api/organisationService';

const OrganisationAutoComplete = (props: {
  setFieldString: string;
  selectedOrganisationId: string;
  source: string;
  placeholder?: string;
  setData?: any;
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<Organisation | null>(null);
  const [options, setOptions] = useState<Organisation[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [openAddOrganisationModal, setOpenAddOrganisationModal] = useState(false);
  const [organisationName, setOrganisationName] = useState<string>('');
  const [organisationEmail, setOrganisationEmail] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const { setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    const IntialOrganisation = async () => {
      if (props.selectedOrganisationId) {
        try {
          const selectedOrganisation = await axiosClient.get(
            `/organisation/${props.selectedOrganisationId}`
          );
          setValue(selectedOrganisation.data.data);
        } catch (e) {
          console.log(e);
        }
      }
    };

    IntialOrganisation();
  }, [props.selectedOrganisationId]);

  const fetch = useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (results?: readonly Organisation[]) => void
        ) => {
          setLoading(true);
          await axiosClient
            .get(`/organisation/search?q=${request.input}`)
            .then((res: any) => {
              callback(res.data.data);
              setLoading(false);
            })
            .catch((err: any) => {
              console.log(err);
              setLoading(false);
            });
        },
        500
      ),
    []
  );

  useEffect(() => {
    let active = true;

    fetch({ input: inputValue }, (results?: any) => {
      if (active) {
        let newOptions: Organisation[] = [];
        if (results) {
          newOptions = results;
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const AddOrganisationHandler = () => {
    setOrganisationName(inputValue);
    setOpenAddOrganisationModal(true);
  };

  const CloseAddOrganisationModal = () => {
    setOpenAddOrganisationModal(false);
  };

  return (
    <>
      <FormDialog
        sx={{ minHeight: '25vh' }}
        open={openAddOrganisationModal}
        onClose={CloseAddOrganisationModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <DialogTitle>
          <Typography variant="h3" component="p" textAlign="center" sx={{ width: '100%' }}>
            Add Organisation
          </Typography>
          <FormIconButton aria-label="close" onClick={CloseAddOrganisationModal}>
            <CloseIcon fontSize="medium" sx={{ color: '#7C7C7C' }} />
          </FormIconButton>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            sx={{ width: '100%' }}>
            <FormTextField
              sx={{ width: '100%' }}
              placeholder="Enter Organisation Name"
              value={organisationName}
              onChange={(event) => setOrganisationName(event?.target.value)}
            />
            <FormTextField
              sx={{ width: '100%' }}
              placeholder="Input Email Address"
              value={organisationEmail}
              onChange={(event) => setOrganisationEmail(event?.target.value)}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: '50px' }}>
              <PrimaryButton
                sx={{ background: '#EEEEEE' }}
                onClick={async () => {
                  const newOrganisation: Organisation =
                    await organisationService.createOrganisationProfile(
                      { title: organisationName, email: organisationEmail },
                      props.source
                    );
                  setOptions([...options, newOrganisation]);
                  setFieldValue(props.setFieldString, newOrganisation._id);
                  setValue(newOrganisation);
                  CloseAddOrganisationModal();
                }}>
                Submit
              </PrimaryButton>
            </Stack>
          </Stack>
        </DialogContent>
      </FormDialog>
      <Autocomplete
        sx={{ width: '100%' }}
        id="get-organisation"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        noOptionsText={
          <PrimaryButton variant="contained" fullWidth onClick={AddOrganisationHandler}>
            Add Organisation
          </PrimaryButton>
        }
        filterSelectedOptions
        getOptionLabel={(option) => option.title}
        value={value}
        onChange={(event: any, newValue: any) => {
          if (props.setData) props.setData(newValue?._id);
          setFieldValue(props.setFieldString, newValue?._id);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        isOptionEqualToValue={(option: Organisation, val: Organisation) =>
          option.organisationId === val.organisationId
        }
        renderInput={(params) => (
          <FormikInputField
            {...params}
            name={props.setFieldString}
            placeholder={props.placeholder || 'Search Education Organisation'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        renderOption={(prop, option) => {
          return (
            <li {...prop} key={option.organisationId}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                </Grid>
                <Grid item xs>
                  <span>{option.title}</span>
                  <Typography variant="body2" color="text.secondary">
                    {option.address
                      ? `${option.address?.addressLineOne}, ${option.address?.addressLineTwo}`
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
};

export default OrganisationAutoComplete;
