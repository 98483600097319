// libs
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { IUser } from 'context/UserContext';
import { createContext, useContext } from 'react';
export interface FormInfoFieldsInterface {
  id: string;
  header: string;
  icon: OverridableComponent<SvgIconTypeMap<any>> & {
    muiName: string;
  };
  data:
    | Array<{
        caption: string;
        title: string;
        subTitle: string;
        description: string;
        additionalData?: any;
      }>
    | Array<string>;
  form: React.MemoExoticComponent<(props: any) => JSX.Element>;
}

interface ProfileFormContext {
  submitForm?: (
    values: any,
    setIsLoading: (arg0: boolean) => void,
    otherActions?: any
  ) => Promise<any>;
  deleteFormEntry?: (
    index: number,
    values: any,
    setIsLoading: (arg0: boolean) => void
  ) => Promise<any>;
  FormInfoFields?: FormInfoFieldsInterface[];
  mode: 'edit' | 'view';
  user: IUser;
}

const initialValue: ProfileFormContext = {
  mode: 'view',
  user: {
    _id: '',
    userId: '',
    email: '',
    createdTimeStamp: '',
    updatedTimeStamp: '',
    firstName: '',
    middleName: '',
    lastName: '',
    password: '',
    countryCode: '',
    contactNumber: '',
    addressLineOne: '',
    addressLineTwo: '',
    zipCode: '',
    instagramLink: '',
    twitterLink: '',
    facebookLink: '',
    aboutMe: '',
    educations: [],
    member: [],
    specialAbilities: [],
    awards: [],
    brandAmbassadors: [],
    publications: [],
    mediaCoverages: [],
    artCollections: [],
    interests: [],
    googleId: '',
    picture: '',
    banner: '',
    isKycVerified: false,
    isEmailVerified: false,
    loginType: ''
  }
};

const profileFormContext = createContext(initialValue);

export const useProfileFormContext = () => useContext(profileFormContext);

export default profileFormContext;
