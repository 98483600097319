import { default as axios } from './Instance';

const createAuction: (data: any) => Promise<any> = async (data: any) => {
  try {
    const response = await axios.post(`/auctions`, data);
    return response.data.data;
  } catch (error) {
    throw new Error('error on auction create');
  }
};

const updateAuction: (data: any) => Promise<any> = async (data: any) => {
  try {
    const response = await axios.patch(`/auctions`, data);
    return response.data.data;
  } catch (error) {
    throw new Error('error on auction update');
  }
};

export default {
  createAuction,
  updateAuction
};
