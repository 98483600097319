import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Suspense } from 'react';
import { BrowserRouter, Outlet, Routes } from 'react-router-dom';
import AppRoutes from './common/AppRoutes';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import LoadingSpinner from './components/LoadingSpinner';
import SnackbarProvider from './components/Snackbar/SnackbarProvider';
import UserContextProvider from './context/UserContextProvider';
import theme from './styles/styles';
import { Box } from '@mui/material';
import UploadProvider from 'providers/UploadProviders/UploadProvider';

function App() {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackbarProvider>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh'
              }}>
              <CssBaseline />

              <Header />
              <UploadProvider>
                <Suspense fallback={<LoadingSpinner />}>
                  <Routes>{AppRoutes}</Routes>
                </Suspense>
                <Outlet />
              </UploadProvider>
              <Footer />
            </Box>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;
