import { memo, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormikInputField } from './CommonTextField';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Typography, Stack } from '@mui/material';
import { FormCheckBox } from 'components/CheckBox/CheckBox';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import theme from 'styles/styles';

const AddressForm = (props: any) => {
  const { user } = useContext(UserContext);
  const [isPermanentAddressSame, setIsPermanentAddressSame] = useState<boolean>(
    user.address?.isPermanentAddressSame || true
  );
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const { setFieldValue, handleSubmit, validateForm } = useFormikContext();
  const updateFieldValue = () => {
    if (user.address?.currentAddress?.addressLineOne) {
      setFieldValue('address', user.address);
      setIsPermanentAddressSame(user.address.isPermanentAddressSame);
    } else {
      setFieldValue('address', {
        currentAddress: {
          addressLineOne: '',
          addressLineTwo: '',
          zipCode: ''
        },
        permanentAddress: {
          addressLineOne: '',
          addressLineTwo: '',
          zipCode: ''
        },
        isPermanentAddressSame: true
      });
    }
  };
  useEffect(() => {
    updateFieldValue();
  }, []);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('address.isPermanentAddressSame', event.target.checked);
    setIsPermanentAddressSame(event.target.checked);
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'address') {
        message = 'Please enter your complete Address details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      // alert(message);
      break;
    }
  };

  return (
    <>
      {/* Current Addresss */}
      <FormContainer name="address" onSubmit={onSubmit}>
        <Stack direction="column" justifyContent="space-around" alignItems="center">
          <Typography
            sx={{
              width: '95%'
            }}
            variant="subtitle2">
            Add Current Address
          </Typography>
          <FormikInputField
            name="address.currentAddress.addressLineOne"
            placeholder="Current Address Line 1"
          />
          <FormikInputField
            name="address.currentAddress.addressLineTwo"
            placeholder="Current Address Line 2"
          />
          <FormikInputField name="address.currentAddress.zipCode" placeholder="Pin/Zip Code" />
          <Typography
            variant="subtitle2"
            sx={{
              color: theme.palette.text.secondary,
              width: '95%',
              marginBottom: '15px',
              marginTop: '15px'
            }}>
            <FormCheckBox
              name="address.isPermanentAddressSame"
              checked={isPermanentAddressSame}
              onChange={handleCheckboxChange}
              // value={user.address?.isPermanentAddressSame || false}
            />
            If permanent address is same
          </Typography>
          {/* Permanent Addresss */}
          <Typography
            sx={{
              width: '95%'
            }}
            variant="subtitle2">
            Add Permanent Address
          </Typography>
          <FormikInputField
            name={
              isPermanentAddressSame
                ? 'address.currentAddress.addressLineOne'
                : 'address.permanentAddress.addressLineOne'
            }
            disabled={isPermanentAddressSame}
            placeholder="Current Address Line 1"
          />
          <FormikInputField
            name={
              isPermanentAddressSame
                ? 'address.currentAddress.addressLineTwo'
                : 'address.permanentAddress.addressLineTwo'
            }
            disabled={isPermanentAddressSame}
            placeholder="Current Address Line 2"
          />
          <FormikInputField
            name={
              isPermanentAddressSame
                ? 'address.currentAddress.zipCode'
                : 'address.permanentAddress.zipCode'
            }
            disabled={isPermanentAddressSame}
            placeholder="Pin/Zip Code"
          />
        </Stack>
        {/* Submit Button  */}
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px' }}>
          <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
            Submit
          </PrimaryButton>
        </Stack>
      </FormContainer>
    </>
  );
};

export default memo(AddressForm);
