import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { List } from '@mui/material';

interface ChipData {
  key: number | string;
  label: string;
}

const ListItem = styled('li')(() => ({
  margin: 0.5,
  background: '#EEEEEE',
  height: 'fit-content',
  boxShadow: '-12px -12px 24px rgba(255, 255, 255, 0.6), 12px 12px 24px rgba(142, 209, 252, 0.25)',
  '& MuiChip-root': {
    borderRadius: '10px'
  }
}));

function ChipsArray(props: { data: ChipData[]; setData: (selMentors: any[]) => void }) {
  return (
    <List
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0
      }}
      component="ul">
      {props.data.map((data) => (
        <ListItem key={data.key}>
          <Chip
            label={data.label}
            onDelete={() =>
              props.setData(props.data.filter((item: ChipData) => item.key !== data.key))
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

export default React.memo(ChipsArray);
