export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://d44dtitlu53zc.cloudfront.net/api/v1';
// export const BACKEND_URL = 'http://localhost:5000/api/v1';
export const SESSION_STORAGE_KEY = 'jumbish';
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000';
export const S3_IMAGE_SERVICE_URL =
  process.env.REACT_APP_S3_IMAGE_SERVICE_URL ||
  'https://8naawgpj46.execute-api.ap-south-1.amazonaws.com/uat/s3?key=jumbish-art-storage-uat/';
export const S3_IMAGE_SERVICE_URL_KEY =
  process.env.REACT_APP_S3_IMAGE_SERVICE_URL_KEY || 'MlHvgLZDWh1MdbVoAnlJU8KOD44odg1g3pPSAi7C';
export const WEB_3_HOST_NAME =
  process.env.REACT_APP_WEB_3_HOST_NAME ||
  'https://eq1yzluyr0.execute-api.ap-south-1.amazonaws.com/Dev';
export const WEB_3_API_KEY =
  process.env.REACT_APP_WEB_3_API_KEY || 'qpdToQKC2t8slTqntAcnz2FidpRsQL7V4dNSvecQ';
export const RAZOR_PAY_KEY = process.env.REACT_APP_RAZOR_PAY_KEY || 'rzp_test_LlbAGBMuh8rKLe';
