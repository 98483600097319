import { memo, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormikInputField } from './CommonTextField';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Stack } from '@mui/material';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import { DatePicker } from '@mui/x-date-pickers';
import OrganisationAutoComplete from '../../../components/AutoComplete/OrganisationAutocomplete';
import UserAutocomplete from 'components/AutoComplete/UserAutocomplete';

interface SelectedUser {
  artistName: string;
  artistId: string;
  artistEmail?: string;
}

const AddBookForm = (props: any) => {
  const { user } = useContext(UserContext);
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const [selectedAuthor, setSelectedAuthor] = useState<SelectedUser>({
    artistName: '',
    artistId: '',
    artistEmail: ''
  });
  const [selectedPrefaceWriter, setSelectedPrefaceWriter] = useState<SelectedUser>({
    artistName: '',
    artistId: '',
    artistEmail: ''
  });
  const [selectedPublisher, setSelectedPublisher] = useState('');
  const { values, setFieldError, setFieldValue, handleSubmit, validateForm } =
    useFormikContext<any>();
  const updateFieldValue = () => {
    let userBooks: any = user?.books ? [...user.books] : [];
    let author: SelectedUser = {
      artistName: '',
      artistId: '',
      artistEmail: ''
    };
    let prefaceWriter: SelectedUser = {
      artistName: '',
      artistId: '',
      artistEmail: ''
    };
    if (userBooks.length !== 0 && typeof userBooks[props.index].publisherId !== 'string') {
      author = {
        artistName: `${userBooks[props.index].authorId.firstName} ${
          userBooks[props.index].authorId.lastName
        }`,
        artistId: userBooks[props.index].authorId._id as string,
        artistEmail: ''
      };

      prefaceWriter = {
        artistName: `${userBooks[props.index].prefaceWriterId.firstName} ${
          userBooks[props.index].prefaceWriterId.lastName
        }`,
        artistId: userBooks[props.index].prefaceWriterId._id as string,
        artistEmail: ''
      };

      userBooks = userBooks.map((book: any) => ({
        ...book,
        publisherId: book.publisherId._id,
        authorId: book.authorId._id,
        prefaceWriterId: book.prefaceWriterId._id
      }));
    }
    if (props.submitActionType === 'add') {
      userBooks.unshift({
        publisherId: '',
        bookTitle: '',
        publishionDate: '',
        prefaceWriterId: '',
        authorId: '',
        description: ''
      });
    } else if (props.submitActionType === 'edit') {
      setSelectedAuthor(author);
      setSelectedPrefaceWriter(prefaceWriter);

      setSelectedPublisher(userBooks[props.index].publisherId);
    }
    setFieldValue('books', userBooks);
  };
  useEffect(() => {
    updateFieldValue();
  }, []);
  const handleDateChange = (date: any, dateText: string, keyboardInput?: string) => {
    let inputDate = '';
    if (keyboardInput) {
      try {
        inputDate = new Date(keyboardInput).toISOString();
      } catch (error) {
        setFieldError(dateText, 'Please enter a valid date in mm/dd/yyyy format');
      }
    }
    if (date && !keyboardInput) {
      inputDate = new Date(date).toISOString();
    }
    setFieldValue(dateText, inputDate);
  };
  useEffect(
    () => setFieldValue(`books[${props.index}].authorId`, selectedAuthor.artistId),
    [selectedAuthor]
  );
  useEffect(
    () => setFieldValue(`books[${props.index}].prefaceWriterId`, selectedPrefaceWriter.artistId),
    [selectedPrefaceWriter]
  );
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'books') {
        message = 'Please enter your complete Books details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <FormContainer name="books" onSubmit={onSubmit}>
      <Stack direction="column" justifyContent="space-around" alignItems="center">
        <FormikInputField name={`books[${props.index}].bookTitle`} placeholder="Name of the book" />
        <OrganisationAutoComplete
          setFieldString={`books[${props.index}].publisherId`}
          selectedOrganisationId={selectedPublisher}
          source="Publisher"
          placeholder="Name of the publisher"
        />
        <UserAutocomplete
          selectedUserId={selectedAuthor}
          setData={setSelectedAuthor}
          placeholder="Add author"
          source="Art Author"
        />
        <UserAutocomplete
          selectedUserId={selectedPrefaceWriter}
          setData={setSelectedPrefaceWriter}
          placeholder="Add preface writer"
          source="Art Author"
        />
        <DatePicker
          label="Date: mm/yyyy"
          views={['year', 'month']}
          value={
            typeof values?.books[props.index] !== 'undefined' &&
            values?.books[props.index].publishionDate
              ? values?.books[props.index].publishionDate
              : null
          }
          onChange={(date, input) => {
            handleDateChange(date, `books[${props.index}].publishionDate`, input);
          }}
          renderInput={(params) => (
            <FormikInputField name={`books[${props.index}].publishionDate`} {...params} />
          )}
        />
      </Stack>
      {/* Submit Button  */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: '50px' }}>
        <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
          Submit
        </PrimaryButton>
      </Stack>
    </FormContainer>
  );
};

export default memo(AddBookForm);
