import * as yup from 'yup';
export const addUserInitialValue = {
  firstName: '',
  lastName: '',
  email: ''
};

export const addUserSchema = yup.object({
  lastName: yup.string().required('Last Name is required'),
  firstName: yup.string().required('First Name is required'),
  email: yup.string().email('Please enter a valid Email')
});
