import React, { createContext } from 'react';

export interface IUser {
  _id: string;
  userId?: string;
  email: string;
  createdTimeStamp: string;
  updatedTimeStamp?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  password?: string;
  countryCode?: string;
  contactNumber?: string;
  addressLineOne?: string;
  addressLineTwo?: string;
  zipCode?: string;
  instagramLink?: string;
  twitterLink?: string;
  facebookLink?: string;
  aboutMe?: string;
  awards?: Array<any>;
  publications?: Array<any>;
  artCollections?: Array<any>;
  googleId?: string;
  picture?: string;
  banner?: string;
  isKycVerified?: boolean;
  isEmailVerified?: boolean;
  loginType?: string;
  address?: {
    permanentAddress: {
      addressLineOne: string;
      addressLineTwo: string;
      zipCode: number;
    };
    currentAddress: {
      addressLineOne: string;
      addressLineTwo: string;
      zipCode: number;
    };
    isPermanentAddressSame: boolean;
  };
  member?: Array<{
    title: string;
    organisationId: string;
    startDate: string;
    isCurrentlyMember: boolean;
    endDate?: string;
    description?: string;
  }>;
  educations?: Array<{
    organisationId: string;
    fieldOfStudy: string;
    startDate: string;
    endDate?: string;
    isCurrentlyPursuing: boolean;
  }>;
  workExperiences?: Array<{
    title: string;
    organisationId: string;
    startDate: string;
    endDate?: string;
    description?: string;
    isCurrentlyMember: boolean;
  }>;
  specialAbilities?: Array<{
    title: string;
    startDate: string; //why
    description?: string;
  }>;
  achievements?: Array<{
    title: string;
    startDate: string;
    description?: string;
    attachmentFile?: string;
  }>;
  brandAmbassadors?: Array<{
    title: string;
    organisationId: string;
    startDate: string;
    endDate?: string;
    description?: string;
    isCurrentlyMember: boolean;
  }>;
  books?: Array<{
    bookTitle: string;
    pubilsherId: string;
    authorId: string;
    prefaceWriterId: string;
    publishedDate: string; //which Type of date is this
  }>;
  mediaCoverages?: Array<{
    title: string;
    organisationId: string;
    startDate: string;
    description?: string;
  }>;
  auctions?: Array<string>; //auctionId
  events?: Array<string>; //eventID
  mentors?: Array<string>; //userID or organisationID
  mentees?: Array<string>; //userID
  endorsers?: Array<string>; //userID
  draftedBy?: string;
  interests?: Array<string>;
}

export interface IToken {
  expiresIn: number;
  token: string;
}

interface IUserContextProps {
  isUserLoggedIn: boolean;
  user: IUser;
  token: IToken;
  setUser: React.Dispatch<React.SetStateAction<any>>;
  setToken: React.Dispatch<React.SetStateAction<any>>;
  setIsUserLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialUserInformation: IUserContextProps = {
  user: {
    _id: '',
    userId: '',
    email: '',
    createdTimeStamp: '',
    updatedTimeStamp: '',
    firstName: '',
    middleName: '',
    lastName: '',
    password: '',
    countryCode: '',
    contactNumber: '',
    addressLineOne: '',
    addressLineTwo: '',
    zipCode: '',
    instagramLink: '',
    twitterLink: '',
    facebookLink: '',
    aboutMe: '',
    educations: [],
    member: [],
    specialAbilities: [],
    awards: [],
    brandAmbassadors: [],
    publications: [],
    mediaCoverages: [],
    artCollections: [],
    interests: [],
    googleId: '',
    picture: '',
    banner: '',
    isKycVerified: false,
    isEmailVerified: false,
    loginType: ''
  },
  token: {
    expiresIn: 0,
    token: ''
  },
  isUserLoggedIn: false,
  setUser: () => {},
  setToken: () => {},
  setIsUserLoggedIn: () => {}
};

const UserContext = createContext(initialUserInformation);

export default UserContext;
