import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { SESSION_STORAGE_KEY } from '../constants/auth';
import { default as axios, axiosForImage } from './Instance';

let interceptor: number;
let interceptorForImage: number;

const getCurrentUser = () => {
  const userData = localStorage.getItem(SESSION_STORAGE_KEY);
  if (userData !== null) {
    return JSON.parse(userData);
  } else {
    return null;
  }
};

const setCurrentUser = (userData: any) => {
  try {
    localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(userData));
    const token = userData.tokenData.token;
    const interceptorFunction = (config: AxiosRequestConfig<any>) => {
      if (config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    };
    interceptor = axios.interceptors.request.use(interceptorFunction);
    interceptorForImage = axiosForImage.interceptors.request.use(interceptorFunction);
  } catch (error) {
    console.log(error);
  }
};
const updateCurrentUser = (userData: AxiosResponse<any, any>) => {
  const user = getCurrentUser();
  user.data = userData.data;
  localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(user));
};

const updateEmailAuthentication = () => {
  const user = getCurrentUser();
  if (user !== null) {
    user.data.isEmailVerified = true;
    localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(user));
  }
};

const signUp = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  confirmPassword: string
) => {
  try {
    const response = await axios.post('signup', {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      confirmPassword: confirmPassword
    });
    if (response.status === 409) {
      throw new Error('Email Already Exists');
    }
    setCurrentUser(response.data);
    return response.data;
  } catch (err) {
    throw new Error('User Registration Failed');
  }
};

const login = async (email: string, password: string) => {
  try {
    const response = await axios.post('login', {
      email: email,
      password: password
    });
    setCurrentUser(response.data);
    return response.data;
  } catch (error: any) {
    let message = 'Login Failed';
    if (error.response) {
      message = error.response.data.message;
    }
    throw new Error(message);
  }
};

const logout = () => {
  // add service call to also cancel the jwt token in server
  localStorage.removeItem(SESSION_STORAGE_KEY);
  axios.interceptors.request.eject(interceptor);
  axiosForImage.interceptors.request.eject(interceptorForImage);
};

const resendActivationMail = (email: string) => {
  return axios
    .patch('/resend-activation-mail', { email: email })
    .then((response) => {
      return 'success';
    })
    .catch((error) => {
      throw Error('Reset Password Activation Link Not Generated');
    });
};

const forgotPassword = (email: string) => {
  return axios
    .post('/forgot-password', { email: email })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw Error('Reset Password Activation Link Not Generated');
    });
};

const resetPassword = (
  password: string,
  confirmPassword: string,
  resetToken: string | undefined
) => {
  return axios
    .patch(`/reset-password/${resetToken}`, {
      password: password,
      confirmPassword: confirmPassword
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw Error('Reset Password Failed');
    });
};

const activateAccount = (activateToken: string | undefined) => {
  return axios
    .patch(`/activate/${activateToken}`)
    .then((response) => {
      //removed since we do not want user to be auto loggedin after account activation
      //setCurrentUser(response.data);
      updateEmailAuthentication();
      return response.data;
    })
    .catch((error) => {
      throw Error('Account Activation Failed');
    });
};
const activateGoogleSignUp = (googleToken: string | undefined) => {
  return axios
    .get(`/auth/google/callback${googleToken}`)
    .then((response) => {
      setCurrentUser(response.data);
      return response.data;
    })
    .catch((error) => {
      throw Error('Google Account Activation Failed');
    });
};

const createUserProfile = (userData: any, source: string) => {
  return axios
    .post(`/users/createProfile?source=${source}`, userData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // console.log(error.response);
      if (error?.response?.status === 409) {
        throw new Error('User already exist');
      }
      throw new Error('Unable to add new user');
    });
};

const updateUserProfile = (userData: any) => {
  return axios
    .patch(`/users`, userData)
    .then((response) => {
      updateCurrentUser(response.data);
      return response.data;
    })
    .catch((error) => {
      throw new Error('error on user profile update');
    });
};

const addUserInterest = (interests: Array<string>) => {
  return axios
    .patch(`/users/interest`, { interests })
    .then((response) => {
      updateCurrentUser(response.data);
      return response.data;
    })
    .catch((error) => {
      throw new Error('error on user profile update');
    });
};

const createArtist = (values: any) =>
  axios
    .post('createartist', values)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.status === 409) {
        throw new Error('User Already Exists');
      }
      throw new Error('Register Failed');
    });

export default {
  login,
  signUp,
  logout,
  getCurrentUser,
  resendActivationMail,
  forgotPassword,
  resetPassword,
  activateAccount,
  activateGoogleSignUp,
  addUserInterest,
  createUserProfile,
  updateUserProfile,
  createArtist
};
