import * as React from 'react';
import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '5px',
  width: 16,
  height: 16,
  boxShadow: '-2px -2px 4px #FFFFFF, 2px 2px 4px rgba(24, 26, 43, 0.25)',
  backgroundColor: '#EEEEEE',
  '.Mui-focusVisible &': {
    outline: '2px auto #0093E3',
    outlineOffset: 2
  },
  //   'input:hover ~ &': {
  //     outline: '1px auto #0093E3'
  //   },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#EEEEEE',
  //   backgroundImage: 'linear-gradient(90deg, #0093E3 0%, #0093E3 100.01%)',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%230093E3'/%3E%3C/svg%3E\")",
    content: '""'
  },
  'input:hover ~ &': {
    borderColor: '#0093E3'
  }
});

// Inspired by blueprintjs
export function FormCheckBox(props: CheckboxProps) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' }
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}
