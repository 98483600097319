import React, { useState } from 'react';
import UserContext, { IToken, IUser } from './UserContext';

interface IUserContextProviderProps {
  children: React.ReactNode;
}

const UserContextProvider = ({ children }: IUserContextProviderProps) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>({
    _id: '',
    userId: '',
    email: '',
    createdTimeStamp: '',
    updatedTimeStamp: '',
    firstName: '',
    middleName: '',
    lastName: '',
    password: '',
    countryCode: '',
    contactNumber: '',
    addressLineOne: '',
    addressLineTwo: '',
    zipCode: '',
    instagramLink: '',
    twitterLink: '',
    facebookLink: '',
    aboutMe: '',
    educations: [],
    specialAbilities: [],
    workExperiences: [],
    awards: [],
    brandAmbassadors: [],
    publications: [],
    mediaCoverages: [],
    artCollections: [],
    googleId: '',
    picture: '',
    banner: '',
    isKycVerified: false,
    isEmailVerified: false,
    loginType: ''
  });
  const [token, setToken] = useState<IToken>({
    expiresIn: 0,
    token: ''
  });

  const initialUserInformation = {
    isUserLoggedIn: isUserLoggedIn,
    user: user,
    token: token,
    setToken: setToken,
    setUser: setUser,
    setIsUserLoggedIn: setIsUserLoggedIn
  };

  return <UserContext.Provider value={initialUserInformation}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
