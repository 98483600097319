import { useFormikContext, useField } from 'formik';
import { InputLabel } from '@mui/material';
import { FormTextField } from 'components/TextFields/TextField';

export const FormikInputField = (props: any) => {
  const { handleBlur, handleChange } = useFormikContext<any>();
  const { name, customlabel, placeholder } = props;
  const [field, meta, helpers] = useField(name);
  const { error, touched, value } = meta;

  return (
    <>
      {customlabel && <InputLabel htmlFor={name}>{customlabel}</InputLabel>}
      <FormTextField
        name={name}
        placeholder={placeholder}
        variant="outlined"
        size="small"
        type="text"
        fullWidth
        onBlur={handleBlur}
        onChange={handleChange}
        value={value || ''}
        error={Boolean(touched && error)}
        helperText={touched && error ? error : ''}
        {...props}
      />
    </>
  );
};
