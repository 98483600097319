import { default as axios } from './Instance';

const createEvent: (data: any) => Promise<Event> = async (data: any) => {
  try {
    const response = await axios.post(`/events`, data);
    return response.data.data;
  } catch (error) {
    throw new Error('error on event create');
  }
};

const updateEvent: (data: any) => Promise<Event> = async (data: any) => {
  try {
    const response = await axios.patch(`/events`, data);
    return response.data.data;
  } catch (error) {
    throw new Error('error on event update');
  }
};

export default {
  createEvent,
  updateEvent
};
