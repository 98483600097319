import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Container,
  Tooltip,
  Typography,
  Button
} from '@mui/material';
import {
  ArrowBackIos,
  Description,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  Info,
  Refresh,
  Share,
  Timelapse
} from '@mui/icons-material';
import axiosClient from 'api/Instance';
import getImageFromS3 from 'api/imageService';
import { formatDate } from 'utils/date.utils';
import DetailsTable from './DetailsTable';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormActionsButtons, PrimaryButton, SecondaryButton } from 'components/Buttons/Buttons';
import { useSnackbarContext } from 'components/Snackbar/context';
import UserContext from 'context/UserContext';
import { IArt } from 'providers/ArtProvider/ArtInterface';
import polygonIcon from 'assets/polygon.png';
import copyLinkIcon from 'assets/copyLinkIcon.svg';
import digIcon from 'assets/digIcon.svg';
import phyIcon from 'assets/phyIcon.svg';

const Art = () => {
  const { artId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [art, setArt] = useState<IArt | null>(null);
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const [copyLinkButton, setCopyLinkButton] = React.useState(false);
  const [imageFromS3, setImageFromS3] = useState('');
  const nagivate = useNavigate();
  const { isUserLoggedIn, user } = useContext(UserContext);

  useEffect(() => {
    axiosClient
      .get('art/' + artId?.toString())
      .then((artData: any) => {
        setArt(artData.data.data);
        if (!art?.isMinted) {
          getImageFromS3(`${artData.data.data.artS3Key}`)
            .then((res: any) => {
              setImageFromS3(res);
            })
            .catch((err: any) => console.log('art image not present'));
        }

        setIsLoading(false);
      })
      .catch((err: any) => console.log(err));
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, []);

  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const copyLinkURL = () => {
    navigator.clipboard.writeText(window.location.href);
    showToast(true, 'success', 'Copied To Clipboard');
  };

  const handleEditArt = () => {
    const statusUpdateData = {
      artId: art?.artId,
      status: 'editing'
    };
    try {
      axiosClient.put('/art/status/', statusUpdateData).then((response) => {});
    } catch (error) {
      console.log(error);
    }
    nagivate(`/edit-art/${art?.artId}`);
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Container sx={{ mt: 12, mb: 5 }}>
      {isUserLoggedIn &&
        user.userId === art?.owner?.userId &&
        art?.isMinted == false &&
        art?.statusInfo.mintStarted.status == false && (
          <Box
            sx={{
              height: '110px',
              background:
                'linear-gradient(90.03deg, rgba(238, 238, 238, 0.1) 2.62%, rgba(142, 209, 252, 0.1) 99.97%, rgba(142, 209, 252, 0) 99.98%)'
            }}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              sx={{ height: '100%', width: '100%' }}>
              <SecondaryButton variant="contained" onClick={handleEditArt}>
                EDIT
              </SecondaryButton>
              <Link to={`/mint-art/${art?.artId}`}>
                <PrimaryButton variant="contained" sx={{ marginRight: '25px' }}>
                  MINT
                </PrimaryButton>
              </Link>
            </Stack>
          </Box>
        )}
      {art?.isMinted == true && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
          sx={{ marginBottom: '3%' }}>
          <Link to={'/explore'}>
            <Button sx={{ color: '#181A2B' }}>
              <ArrowBackIos fontSize="medium" fontWeight={600} />
              <Typography fontWeight={600}>Back</Typography>
            </Button>
          </Link>
        </Stack>
      )}
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4}>
          <Card
            sx={{
              backgroundColor: '#EEEEEE',
              margin: 'auto',
              position: 'relative',
              maxWidth: 350,
              maxHeight: 400,
              borderRadius: '10px',
              transition: '0s',
              boxShadow: '-12px -12px 24px #FFFFFF, 12px 12px 24px rgba(142, 209, 252, 0.25)',
              ':hover .child': {
                display: 'block'
              }
            }}>
            <CardHeader
              sc={{ px: 2.5, py: 1, m: 0 }}
              avatar={
                <Box
                  sx={{
                    maxHeight: '25px',
                    maxWidth: '100%',
                    objectFit: 'cover'
                  }}
                  component={'img'}
                  src={polygonIcon}
                  alt="Polygon Logo"
                />
              }
              // action={
              //   <IconButton aria-label="settings">
              //     <FavoriteBorder />
              //   </IconButton>
              // }
            />
            <CardMedia
              sx={{
                position: 'relative',
                zIndex: '1',
                filter: 'blur(5px) opacity(0.2)',
                display: 'block',
                height: 305,
                width: 'auto'
              }}
              image={art?.isMinted ? art?.nftUrl : `data:image/jpg;base64,${imageFromS3}`}
            />
            <CardMedia
              sx={{
                position: 'relative',
                zIndex: '2',
                backgroundSize: 'contain',
                m: 'auto',
                mt: '-305px',
                display: 'block',
                height: 305,
                width: '85%'
              }}
              image={art?.isMinted ? art?.nftUrl : `data:image/jpg;base64,${imageFromS3}`}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Stack
            sx={{ width: '100%' }}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={4}
              width="100%">
              <Typography component="h2" variant="h2" sx={{ marginTop: '2%' }}>
                {art?.title} {art?.isPhygital ? <img src={phyIcon} /> : <img src={digIcon} />}
              </Typography>

              {art?.isMinted == true && (
                <Box
                  style={{
                    background: '#EEEEEE',
                    boxShadow: '-8px -8px 16px #FFFFFF, 8px 8px 16px rgba(24, 26, 43, 0.25)',
                    borderRadius: '5px'
                  }}>
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={0}>
                    <FormActionsButtons onClick={() => window.location.reload()}>
                      <Tooltip title="refresh">
                        <Refresh fontSize="large" sx={{ color: '#7C7C7C', padding: '0.15em' }} />
                      </Tooltip>
                    </FormActionsButtons>
                    <FormActionsButtons
                      onMouseEnter={() => setCopyLinkButton(true)}
                      onMouseLeave={() => setTimeout(() => setCopyLinkButton(false), 1000)}>
                      <Share
                        fontSize="large"
                        sx={{
                          color: '#7C7C7C',
                          padding: '0.15em'
                        }}
                      />
                    </FormActionsButtons>
                  </Stack>
                </Box>
              )}
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
              sx={{ width: '100%' }}>
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={4}
                sx={{ marginTop: '0' }}>
                <Typography
                  variant="h4"
                  fontWeight="600"
                  textOverflow="ellipsis"
                  color="text.secondary">
                  Created by{' '}
                  <Link to={`/profile/${art?.artist.userId}`} style={{ color: '#0093E3' }}>
                    {art?.artist?.firstName} {art?.artist?.lastName}
                  </Link>
                </Typography>
                {/* <Typography
                  variant="h4"
                  fontWeight="600"
                  textOverflow="ellipsis"
                  color="text.secondary">
                  <Favorite sx={{ verticalAlign: 'bottom' }} /> {art?.likes || 0} Favourites
                </Typography> */}
              </Stack>
              <SecondaryButton
                style={{
                  visibility: copyLinkButton ? 'visible' : 'hidden'
                }}
                onClick={copyLinkURL}>
                <img src={copyLinkIcon} />
                <Typography variant="subtitle1" color="text.primary" sx={{ ml: 2 }}>
                  Copy Link
                </Typography>
              </SecondaryButton>
            </Stack>
            {art?.description && (
              <div>
                <Typography variant="h3" fontSize="20" fontWeight="600">
                  <Description sx={{ verticalAlign: 'text-bottom' }} /> Art Description
                </Typography>
                <Typography variant="subtitle2" color="text.secondary" mt={1} pl={4}>
                  {art?.description}
                </Typography>
              </div>
            )}
            <Accordion
              sx={{
                width: '100%',
                maxWidth: '600px',
                background: '#EEEEEE',
                boxShadow: '-8px -8px 16px #FFFFFF, 8px 8px 16px rgba(0, 0, 0, 0.1)',
                mt: '16px !important',
                '&:before': {
                  content: 'none'
                }
              }}
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  minHeight: '64px',
                  '& .Mui-expanded': {
                    my: '12px'
                  }
                }}>
                <Typography variant="body1" fontWeight="600">
                  <Info sx={{ verticalAlign: 'text-bottom', mr: 1 }} />
                  Art Details
                </Typography>
              </AccordionSummary>
              <Divider variant="fullWidth" />
              <AccordionDetails sx={{ p: 0, pl: 4 }}>
                <List sx={{ width: '100%', p: 0 }}>
                  {art?.artist?.firstName && art?.artist?.firstName.length > 0 && (
                    <ListItem alignItems="center">
                      <Typography
                        sx={{ pr: 1 }}
                        component="span"
                        variant="h4"
                        fontWeight="400"
                        color="text.primary">
                        Created By :
                      </Typography>
                      <Link to={`/profile/${art?.artist.userId}`}>
                        <Typography component="span" variant="h4" fontWeight="400" color="primary">
                          {art?.artist?.firstName} {art?.artist?.lastName}
                        </Typography>
                      </Link>
                    </ListItem>
                  )}
                  {art?.owner?.firstName && art?.owner?.firstName.length > 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center">
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Owned By :
                        </Typography>
                        <Link to={`/profile/${art?.owner.userId}`}>
                          <Typography
                            component="span"
                            variant="h4"
                            fontWeight="400"
                            color="primary">
                            {art?.owner?.firstName} {art?.owner?.lastName}
                          </Typography>
                        </Link>
                      </ListItem>
                    </>
                  )}
                  {art?.toolsUsed && art?.toolsUsed.length > 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center" sx={{ flexWrap: 'wrap' }}>
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Tools Used :
                        </Typography>
                        {art?.toolsUsed.map((toolUsed: string) => (
                          <Typography
                            component="span"
                            variant="h4"
                            fontWeight="400"
                            color="primary"
                            key={toolUsed}
                            sx={{ mr: 1 }}>
                            {toolUsed.charAt(0).toUpperCase() + toolUsed.slice(1)}
                          </Typography>
                        ))}
                      </ListItem>
                    </>
                  )}
                  {art?.medium && art?.medium.length > 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center" sx={{ flexWrap: 'wrap' }}>
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Medium Used :
                        </Typography>
                        {art?.medium.map((med: string) => (
                          <Typography
                            component="span"
                            variant="h4"
                            fontWeight="400"
                            color="primary"
                            key={med}
                            sx={{ mr: 1 }}>
                            {med.charAt(0).toUpperCase() + med.slice(1)}
                          </Typography>
                        ))}
                      </ListItem>
                    </>
                  )}
                  {art?.material && art?.material.length > 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center" sx={{ flexWrap: 'wrap' }}>
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Material Used :
                        </Typography>
                        {art?.material.map((mat: string) => (
                          <Typography
                            component="span"
                            variant="h4"
                            fontWeight="400"
                            color="primary"
                            key={mat}
                            sx={{ mr: 1 }}>
                            {mat.charAt(0).toUpperCase() + mat.slice(1)}
                          </Typography>
                        ))}
                      </ListItem>
                    </>
                  )}
                  {art?.type && art?.type?.length > 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center" sx={{ flexWrap: 'wrap' }}>
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Type of ArtWork :
                        </Typography>
                        {art?.type.map((artType: string) => (
                          <Typography
                            component="span"
                            variant="h4"
                            fontWeight="400"
                            color="primary"
                            key={artType}
                            sx={{ mr: 1 }}>
                            {artType.charAt(0).toUpperCase() + artType.slice(1)}
                          </Typography>
                        ))}
                      </ListItem>
                    </>
                  )}
                  {art?.dateOfArtWork && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center">
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Date Of ArtWork :
                        </Typography>
                        <Typography component="span" variant="h4" fontWeight="400" color="primary">
                          {formatDate('MMM yyyy', new Date(art?.dateOfArtWork))}
                        </Typography>
                      </ListItem>
                    </>
                  )}
                  {art?.size && art?.size !== '' && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center">
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Size used :
                        </Typography>
                        <Typography component="span" variant="h4" fontWeight="400" color="primary">
                          {art?.size} cm
                        </Typography>
                      </ListItem>
                    </>
                  )}
                  {Boolean(art?.weight) && art?.weight !== 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center">
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Weight :
                        </Typography>
                        <Typography component="span" variant="h4" fontWeight="400" color="primary">
                          {art?.weight ? <>{art.weight} KG</> : ''}
                        </Typography>
                      </ListItem>
                    </>
                  )}
                  {art?.royaltyPercent !== 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center">
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Royality :
                        </Typography>
                        <Typography component="span" variant="h4" fontWeight="400" color="primary">
                          {art?.royaltyPercent}%
                        </Typography>
                      </ListItem>
                    </>
                  )}
                  {art?.copyright && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center">
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Copyright :
                        </Typography>
                        <Typography component="span" variant="h4" fontWeight="400" color="primary">
                          {art?.copyright}
                        </Typography>
                      </ListItem>
                    </>
                  )}
                  {art?.genre && art?.genre.length > 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center" sx={{ flexWrap: 'wrap' }}>
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Genre :
                        </Typography>
                        {art?.genre.map((gen: string) => (
                          <Typography
                            component="span"
                            variant="h4"
                            fontWeight="400"
                            color="primary"
                            key={gen}
                            sx={{ mr: 1 }}>
                            {gen.charAt(0).toUpperCase() + gen.slice(1)}
                          </Typography>
                        ))}
                      </ListItem>
                    </>
                  )}
                  {art?.muse && art.muse.length > 0 && (
                    <>
                      <Divider variant="fullWidth" />
                      <ListItem alignItems="center" sx={{ flexWrap: 'wrap' }}>
                        <Typography
                          sx={{ pr: 1 }}
                          component="span"
                          variant="h4"
                          fontWeight="400"
                          color="text.primary">
                          Muse :
                        </Typography>
                        {art?.muse.map((data) => (
                          <Link to={`/profile/${data?.userId}`} key={data.userId}>
                            <Typography
                              component="span"
                              variant="h4"
                              fontWeight="400"
                              color="primary"
                              // key={data.userId}
                              sx={{ mr: 1 }}>
                              {data.firstName} {data.lastName}
                            </Typography>
                          </Link>
                        ))}
                      </ListItem>
                    </>
                  )}
                </List>
              </AccordionDetails>
            </Accordion>
            {/* // rate box here  */}
            {art?.isMinted == true && (
              <Box
                sx={{
                  width: '100%',
                  maxWidth: '600px',
                  background: '#EEEEEE',
                  boxShadow: '-8px -8px 16px #FFFFFF, 8px 8px 16px rgba(0, 0, 0, 0.1)',
                  borderRadius: '5px'
                }}>
                <Typography
                  color="text.secondary"
                  variant="button"
                  component="h4"
                  textTransform="none"
                  m={2}>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center">
                    <Timelapse sx={{ color: '#000', mr: 1 }} />
                    Uploaded on {formatDate('MMM yyyy', new Date(art?.createdTimestamp))}
                  </Stack>
                </Typography>
                <Divider variant="fullWidth" />
                <Typography variant="body1" pl={6} mt={2} fontWeight="600">
                  Current Price
                </Typography>
                <Typography display="inline" color="text.secondary" variant="subtitle1" pl={6}>
                  USD
                </Typography>
                <Typography display="inline" variant="h4" fontWeight="800">
                  {' $' + art?.rate}
                </Typography>
                <br />
                {isUserLoggedIn && user.userId === art?.owner?.userId ? (
                  <Link to={`/mint-art/${art?.artId}`}>
                    <PrimaryButton sx={{ my: 2, ml: 6 }} variant="contained">
                      Edit Price
                    </PrimaryButton>
                  </Link>
                ) : (
                  <PrimaryButton sx={{ my: 2, ml: 6 }} variant="contained">
                    Buy Now
                  </PrimaryButton>
                )}
              </Box>
            )}
          </Stack>
        </Grid>
      </Grid>
      {art?.isMinted == true && (
        <Grid item xs={12} sm={12} md={12} sx={{ mb: 10 }}>
          <DetailsTable {...art} />
        </Grid>
      )}
    </Container>
  );
};

export default Art;
