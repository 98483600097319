import { memo, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormIconButton, PrimaryButton } from 'components/Buttons/Buttons';
import { FormContainer } from './FormContainer';
import { Box, Stack } from '@mui/material';
import UserAutocomplete from 'components/AutoComplete/UserAutocomplete';
import { AddOutlined } from '@mui/icons-material';
import UserContext from 'context/UserContext';
import { useSnackbarContext } from 'components/Snackbar/context';
import ChipsArray from 'components/ChipArray/ChipArray';

const MentorForm = (props: any) => {
  const { user } = useContext(UserContext);
  const [selectedMentors, setSelectedMentors] = useState<any[]>([]);
  const [autocompleteData, setAutocompleteData] = useState<{
    artistName: string;
    artistId: string;
  }>({
    artistName: '',
    artistId: ''
  });
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const { values, setFieldValue, handleSubmit, validateForm } = useFormikContext<any>();

  const updateFieldValue = () => {
    const userMentors: string[] = user?.mentors ? [...user.mentors] : [];
    setSelectedMentors(userMentors);
    if (userMentors.length !== 0 && typeof userMentors[0] !== 'string')
      userMentors.map((userMentor: any) => {
        userMentor = userMentor._id;
      });
    setFieldValue('mentors', userMentors);
  };

  useEffect(() => {
    updateFieldValue();
  }, []);

  const getFirstName = (fullName?: string) => {
    const lastIndex = fullName?.lastIndexOf(' ');
    return lastIndex ? fullName?.slice(0, lastIndex) : '';
  };

  const getLastName = (fullName?: string) => {
    const lastIndex = fullName?.lastIndexOf(' ');
    return lastIndex ? fullName?.slice(lastIndex + 1) : '';
  };

  const addMentorHandler = () => {
    const newSelectedMentors = selectedMentors;
    newSelectedMentors.push({
      _id: autocompleteData.artistId,
      firstName: getFirstName(autocompleteData.artistName),
      lastName: getLastName(autocompleteData.artistName)
    });

    const userMentors: string[] = [];
    if (newSelectedMentors.length !== 0) {
      newSelectedMentors.map((userMentor: any) => {
        userMentors.push(userMentor._id);
      });
    }
    setFieldValue('mentors', userMentors);
    setSelectedMentors(newSelectedMentors);
    setAutocompleteData({ artistId: '', artistName: '' });
  };

  const chipArrayDataChange = (selMentors: any[]) => {
    const newSelectedMentors = selMentors.map((userMentor: any) => userMentor.key);
    setFieldValue('mentors', newSelectedMentors);

    setSelectedMentors(
      selMentors.map((userMentor: any) => ({
        _id: userMentor.key,
        firstName: getFirstName(userMentor.label),
        lastName: getLastName(userMentor.label)
      }))
    );
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'mentors') {
        message = 'Please enter your complete Address details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <Stack flexDirection="column" width="100%">
      <Box>
        <ChipsArray
          data={
            selectedMentors.map((m: any) => ({
              key: m._id,
              label: m.firstName + ' ' + m.lastName
            })) || []
          }
          setData={chipArrayDataChange}
        />
      </Box>

      <FormContainer name="mentors" onSubmit={onSubmit}>
        <Stack direction="row" alignItems="center">
          <UserAutocomplete
            selectedUserId={autocompleteData}
            setData={setAutocompleteData}
            placeholder="Add mentor name"
            source="mentor"
          />
          <FormIconButton>
            <AddOutlined sx={{ color: '#7C7C7C' }} onClick={addMentorHandler} />
          </FormIconButton>
        </Stack>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px' }}>
          <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
            Submit
          </PrimaryButton>
        </Stack>
      </FormContainer>
    </Stack>
  );
};

export default memo(MentorForm);
