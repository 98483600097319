import { useContext, memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import AuthContext, { FormInfoFieldsInterface } from './context';
import UserContext, { IUser } from 'context/UserContext';
import userService from 'api/userService';
import { useSnackbarContext } from 'components/Snackbar/context';
import AddressForm from 'pages/Profile/ProfileForms/AddressForm';
import EducationForm from 'pages/Profile/ProfileForms/EducationForm';
import { formatDate } from 'utils/date.utils';
import SpecialAbilityForm from 'pages/Profile/ProfileForms/SpecialAbilityForm';
import WorkExperienceForm from 'pages/Profile/ProfileForms/WorkExperienceForm';
import AchievementsForms from 'pages/Profile/ProfileForms/AchievementsForms';
import BrandAmbassadorForm from 'pages/Profile/ProfileForms/BrandAmbassadorForm';
import AddBookForm from 'pages/Profile/ProfileForms/AddBookForm';
import MediaCoverage from 'pages/Profile/ProfileForms/MediaCoverage';
import AddAuctionsForm from 'pages/Profile/ProfileForms/AddAuctionsForm';
import AddEventForm from 'pages/Profile/ProfileForms/AddEventForm';
import MemberForm from 'pages/Profile/ProfileForms/MemberForm';
import MentorForm from 'pages/Profile/ProfileForms/MentorForm';
import MenteeForm from 'pages/Profile/ProfileForms/MenteeForm';
import InterestForm from 'pages/Profile/ProfileForms/InterestForm';
import {
  AchievementsIcon,
  AddressIcon,
  AuctionIcon,
  BooksIcon,
  BrandAmbassdorIcon,
  EducationIcon,
  EndorsersIcon,
  EventsIcon,
  MediaCoverageIcon,
  MemberIcon,
  MenteesIcon,
  MentorIcon,
  specialAbilityIcon,
  workExperienceIcon
} from 'assets/SVG/ProfileSVG';
import axiosClient from 'api/Instance';
import { useUploadContext } from 'providers/UploadProviders/context';

interface ProviderProps {
  children: React.ReactNode;
  mode: 'view' | 'edit';
}

function ProfileFormProvider({ children, mode }: ProviderProps) {
  const { user, setUser } = useContext(UserContext);
  const [selectedUser, setSelectedUser] = useState<IUser>(user);
  const { userId } = useParams();
  const {
    ToastService: { showToast }
  } = useSnackbarContext();
  const { deleteFile } = useUploadContext();
  useEffect(() => {
    if (userId && mode === 'view') {
      axiosClient
        .get('users/user/' + userId?.toString())
        .then((userData) => {
          setSelectedUser(userData.data.data);
        })
        .catch((err) => showToast(true, 'error', 'Failed to fetch user'));
    } else if (mode === 'edit') {
      setSelectedUser(user);
    }
  }, [user, mode, userId]);
  const submitForm = useCallback(
    async (values: any, setIsLoading: (arg0: boolean) => void, otherActions?: any) => {
      setIsLoading(true);
      // const updatedUser: any = { ...user };
      // for (const key in values) updatedUser[key] = values[key];

      try {
        const response = await userService.updateUserProfile(values);
        setUser(response.data);
        if ('email' in values) await userService.resendActivationMail(values.email);
        setIsLoading(false);
        if (otherActions.closeModalHandler) otherActions.closeModalHandler();
        showToast(true, 'success', 'Profile information updated');
      } catch (e: any) {
        setIsLoading(false);
        showToast(true, 'error', 'Failed to update profile information');
      }
    },
    [user]
  );

  const FormInfoFields: FormInfoFieldsInterface[] = useMemo(
    () => [
      {
        id: 'address',
        header: 'Address',
        icon: AddressIcon,
        data: selectedUser?.address?.currentAddress?.addressLineOne
          ? selectedUser?.address?.isPermanentAddressSame
            ? [
                {
                  caption: 'Permanent Address/ Local Address',
                  title: selectedUser.address.currentAddress.addressLineOne || '',
                  subTitle:
                    `${selectedUser.address.currentAddress.addressLineTwo}, ${selectedUser.address.currentAddress.zipCode}` ||
                    '',
                  description: ''
                }
              ]
            : [
                {
                  caption: 'Permanent Address',
                  title: selectedUser?.address?.permanentAddress.addressLineOne || '',
                  subTitle:
                    `${selectedUser?.address?.permanentAddress.addressLineTwo}, ${selectedUser?.address?.permanentAddress.zipCode}` ||
                    '',
                  description: ''
                },
                {
                  caption: 'Local Address',
                  title: selectedUser?.address?.currentAddress.addressLineOne || '',
                  subTitle:
                    `${selectedUser?.address?.currentAddress.addressLineTwo}, ${selectedUser?.address?.currentAddress.zipCode}` ||
                    '',
                  description: ''
                }
              ]
          : [],
        form: AddressForm
      },
      {
        id: 'member',
        header: 'Member',
        icon: MemberIcon,
        data:
          selectedUser.member?.map((memberTemp: any) => {
            return {
              caption: memberTemp.title || '',
              title: memberTemp?.organisationId?.title || '',
              subTitle:
                `${formatDate('MMM yyyy', memberTemp.startDate)} - ${
                  memberTemp.isCurrentlyMember
                    ? 'present'
                    : formatDate('MMM yyyy', memberTemp.endDate)
                }` || '',
              description: memberTemp.description
            };
          }) || [],
        form: MemberForm
      },
      {
        id: 'educations',
        header: 'Education / Certification',
        icon: EducationIcon,
        data:
          selectedUser.educations?.map((education: any) => {
            return {
              caption: '',
              title: education?.organisationId?.title || '',
              subTitle:
                `${education.fieldOfStudy}, ${formatDate('MMM yyyy', education.startDate)} - ${
                  education.isCurrentlyPursuing
                    ? 'present'
                    : formatDate('MMM yyyy', education.endDate)
                }` || '',
              description: ''
            };
          }) || [],

        form: EducationForm
        // EducationForm | {(props:any) => {return(<form></form>)}}
      },
      {
        id: 'specialAbilities',
        header: 'Special Ability',
        icon: specialAbilityIcon,
        data:
          selectedUser.specialAbilities?.map((specialAbility: any) => {
            return {
              caption: '',
              title: specialAbility.title || '',
              subTitle: ` ${formatDate('MMM yyyy', specialAbility.startDate)}` || '',
              description: specialAbility.description
            };
          }) || [],

        form: SpecialAbilityForm
        // EducationForm | {(props:any) => {return(<form></form>)}}
      },
      {
        id: 'workExperiences',
        header: 'Work Experiences',
        icon: workExperienceIcon,
        data:
          selectedUser.workExperiences?.map((workExperience: any) => {
            return {
              caption: workExperience.title || '',
              title: workExperience?.organisationId?.title || '',
              subTitle:
                `${formatDate('MMM yyyy', workExperience.startDate)} - ${
                  workExperience.isCurrentlyMember
                    ? 'present'
                    : formatDate('MMM yyyy', workExperience.endDate)
                }` || '',
              description: workExperience.description || ''
            };
          }) || [],

        form: WorkExperienceForm
        // EducationForm | {(props:any) => {return(<form></form>)}}
      },
      {
        id: 'achievements',
        header: 'Achievements',
        icon: AchievementsIcon,
        data:
          selectedUser.achievements?.map((achievement: any) => {
            return {
              caption: '',
              title: achievement.title || '',
              subTitle: ` ${formatDate('MMM yyyy', achievement.startDate)}` || '',
              description: achievement.description
            };
          }) || [],
        form: AchievementsForms
      },
      {
        id: 'brandAmbassadors',
        header: 'Brand Ambassador',
        icon: BrandAmbassdorIcon,
        data:
          selectedUser.brandAmbassadors?.map((brandAmbassador: any) => {
            return {
              caption: brandAmbassador.title || '',
              title: brandAmbassador?.organisationId?.title || '',
              subTitle:
                `${formatDate('MMM yyyy', brandAmbassador.startDate)} - ${
                  brandAmbassador.isCurrentlyMember
                    ? 'present'
                    : formatDate('MMM yyyy', brandAmbassador.endDate)
                }` || '',
              description: ''
            };
          }) || [],
        form: BrandAmbassadorForm
      },
      {
        id: 'books',
        header: 'Books',
        icon: BooksIcon,
        data: selectedUser.books
          ? selectedUser.books?.map((book: any) => {
              return (
                {
                  title: book.bookTitle as string,
                  caption: (book.publisherId.title as string) || ('' as string),
                  subTitle: formatDate('MMM yyyy', book.publishionDate),
                  description: '',
                  additionalData: {
                    author: `${book.authorId.firstName || ''} ${book.authorId.lastName || ''}`,
                    prefaceWriter: `${book.prefaceWriterId.firstName || ''} ${
                      book.prefaceWriterId.lastName || ''
                    }`
                  }
                } || []
              );
            })
          : [],
        form: AddBookForm
      },
      {
        id: 'mediaCoverages',
        header: 'Media Coverage',
        icon: MediaCoverageIcon,
        data:
          selectedUser.mediaCoverages?.map((mediaCoverage: any) => {
            return {
              caption: mediaCoverage.title || '',
              title: mediaCoverage?.organisationId?.title || '',
              subTitle: ` ${formatDate('MMM yyyy', mediaCoverage.startDate)}` || '',
              description: mediaCoverage?.description || ''
            };
          }) || [],
        form: MediaCoverage
      },
      {
        id: 'auctions',
        header: 'Auctions',
        icon: AuctionIcon,
        data:
          selectedUser.auctions?.map((auction: any) => {
            return {
              caption: `${auction.curator?.firstName} ${auction.curator?.lastName}` || '',
              title: `${auction.auctioneer?.firstName} ${auction.auctioneer?.lastName}` || '',
              subTitle:
                `${formatDate('MMM yyyy', auction.startDate)} - ${formatDate(
                  'MMM yyyy',
                  auction.endDate
                )} ,  ${auction.venue}` || '',
              description: ''
            };
          }) || [],
        form: AddAuctionsForm
      },
      {
        id: 'events',
        header: 'Events',
        icon: EventsIcon,
        data:
          selectedUser.events?.map((EventLine: any) => {
            return {
              caption: '',
              title: EventLine.eventName || '',
              subTitle:
                ` ${formatDate('MMM yyyy', EventLine.startDate)} -  ${formatDate(
                  'MMM yyyy',
                  EventLine.endDate
                )} , ${EventLine.venue}` || '',
              description: EventLine.description
            };
          }) || [],
        form: AddEventForm
      },
      {
        id: 'mentors',
        header: 'Mentors',
        icon: MentorIcon,
        data: selectedUser.mentors || [],
        form: MentorForm
      },
      {
        id: 'mentees',
        header: 'Mentees',
        icon: MenteesIcon,
        data: selectedUser.mentees || [],
        form: MenteeForm
      },
      {
        id: 'address',
        header: 'Endorsers',
        icon: EndorsersIcon,
        data: selectedUser.endorsers || [],
        form: AchievementsForms
      },
      {
        id: 'interests',
        header: 'Interests',
        icon: EndorsersIcon,
        data: selectedUser.interests || [],
        form: InterestForm
      }
    ],
    [selectedUser]
  );

  const deleteFormEntry = useCallback(
    async (index: number, values: Array<string>, setIsLoading: (arg0: boolean) => void) => {
      setIsLoading(true);
      const updatedUser: any = { ...user };
      let s3attachmentFile = '';
      values.map((key: string) => {
        if (Array.isArray(updatedUser[key])) {
          if (key === 'achievements') {
            const userAcheiments = updatedUser[key] || [];
            s3attachmentFile = userAcheiments[index].attachmentFile;
          }
          updatedUser[key].splice(index, 1);
        } else {
          updatedUser[key] = null;
        }
      });
      if (deleteFile && s3attachmentFile) await deleteFile(s3attachmentFile);

      userService
        .updateUserProfile(updatedUser)
        .then((response) => {
          setUser(response.data);
          setIsLoading(false);
          showToast(true, 'success', 'Profile information updated');
        })
        .catch(() => {
          setIsLoading(false);
          showToast(true, 'error', 'Failed to update profile information');
        });
    },
    [user]
  );

  const values = useMemo(
    () => ({
      submitForm,
      deleteFormEntry,
      FormInfoFields,
      mode,
      user: selectedUser
    }),
    [submitForm, deleteFormEntry, FormInfoFields, mode, selectedUser]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export default memo(ProfileFormProvider);
