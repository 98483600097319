import * as React from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { debounce } from 'lodash';
import axiosClient from 'api/Instance';
import { FormDialog } from 'components/Modal/Modal';
import { FormIconButton, LoadingButton, PrimaryButton } from 'components/Buttons/Buttons';
import { FormTextField } from 'components/TextFields/TextField';
import { useState } from 'react';
import { useSnackbarContext } from 'components/Snackbar/context';
import { addUserInitialValue, addUserSchema } from './contants';
import { useFormik } from 'formik';
import userService from 'api/userService';
interface IUserSearchResults {
  artistName: string;
  artistId: string;
  artistEmail: string;
}

interface IValue {
  artistName: string;
  artistId: string;
  artistEmail?: string;
}

export default function UserAutocomplete(props: {
  selectedUserId: any;
  setData: (newMentor: any) => void;
  placeholder?: string;
  source?: string;
  onKeyDown?: (keyEvent: any) => void;
  disabled?: boolean;
}) {
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState<IValue>({ artistId: '', artistName: '' });
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly IUserSearchResults[]>([
    { artistId: '', artistName: '', artistEmail: '' }
  ]);

  const [loading, setLoading] = useState(false);

  const fetch = React.useMemo(
    () =>
      debounce(
        async (
          request: { input: string },
          callback: (results?: readonly IUserSearchResults[]) => void
        ) => {
          setLoading(true);
          await axiosClient
            .get(`/users/search?q=${request.input}`)
            .then((res: any) => {
              callback(res.data.data);
              setLoading(false);
            })
            .catch((err: any) => {
              console.log(err);
              setLoading(false);
            });
        },
        500
      ),
    []
  );

  const getFullName = (option: any) => {
    let name = '';
    if (option?.firstName !== undefined && option?.firstName !== null) name += option.firstName;
    if (option?.lastName !== undefined && option?.lastName !== null)
      name = name.trim() + ' ' + option.lastName;

    return name.trim();
  };

  const getFirstName = (fullName?: string) => {
    const lastIndex = fullName?.lastIndexOf(' ');
    return lastIndex ? fullName?.slice(0, lastIndex) : '';
  };

  const getLastName = (fullName?: string) => {
    const lastIndex = fullName?.lastIndexOf(' ');
    return lastIndex ? fullName?.slice(lastIndex + 1) : '';
  };

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      return undefined;
    }

    fetch({ input: inputValue }, (results?: any) => {
      if (active) {
        let newOptions: IUserSearchResults[] = [];
        if (results) {
          newOptions = results.map((result: any) => {
            return {
              artistId: result._id,
              artistName: getFullName(result),
              artistEmail: result.email
            };
          });
        }
        newOptions.push({ artistId: '', artistName: '', artistEmail: '' });
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  React.useEffect(() => {
    if (props.selectedUserId) setValue(props.selectedUserId);
  }, [props.selectedUserId]);

  const CloseAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  const addUserHandler = async (
    values: { firstName: string; lastName: string; email: string },
    setSubmitting: (arg0: boolean) => void
  ) => {
    setSubmitting(true);
    try {
      const addedUser = (await userService.createUserProfile(values, props.source || 'artist'))
        .data;

      setOptions([
        ...options,
        {
          artistId: addedUser._id,
          artistName: getFullName(addedUser),
          artistEmail: addedUser.email
        }
      ]);
      setValue({
        artistId: addedUser._id,
        artistName: getFullName(addedUser),
        artistEmail: addedUser.email
      });
      props.setData({
        artistId: addedUser._id,
        artistName: getFullName(addedUser),
        artistEmail: addedUser.email
      });
      setSubmitting(false);
      CloseAddUserModal();
    } catch (e: any) {
      setSubmitting(false);
      CloseAddUserModal();
      showToast(true, 'error', e.message);
    }
  };

  const formik = useFormik({
    initialValues: addUserInitialValue,
    validationSchema: addUserSchema,
    onSubmit: (values, { setSubmitting }) => {
      addUserHandler(values, setSubmitting);
    }
  });

  const AddUserHandler = async () => {
    await formik.setFieldValue('lastName', getLastName(inputValue) || '');
    await formik.setFieldValue('firstName', getFirstName(inputValue) || '');
    setOpenAddUserModal(true);
  };

  return (
    <>
      <FormDialog
        sx={{ minHeight: '25vh' }}
        open={openAddUserModal}
        onClose={CloseAddUserModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <DialogTitle>
          <Typography variant="h3" component="p" textAlign="center" sx={{ width: '100%' }}>
            Add User
          </Typography>
          <FormIconButton aria-label="close" onClick={CloseAddUserModal}>
            <CloseIcon fontSize="medium" sx={{ color: '#7C7C7C' }} />
          </FormIconButton>
        </DialogTitle>
        <DialogContent>
          <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 1 }}>
            <FormTextField
              name="firstName"
              sx={{ width: '100%' }}
              placeholder="Enter First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.firstName)}
              helperText={formik.errors.firstName}
            />
            <FormTextField
              name="lastName"
              sx={{ width: '100%' }}
              placeholder="Enter Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.lastName)}
              helperText={formik.errors.lastName}
            />
            <FormTextField
              name="email"
              sx={{ width: '100%' }}
              placeholder="Input Email Address"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.email)}
              helperText={formik.errors.email}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: '50px' }}>
              <LoadingButton
                sx={{ background: '#EEEEEE' }}
                loading={formik.isSubmitting}
                type="submit">
                Submit
              </LoadingButton>
            </Stack>
          </Box>
        </DialogContent>
      </FormDialog>
      <Autocomplete
        id="get-artist"
        open={open}
        disabled={props.disabled}
        sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        noOptionsText={
          <PrimaryButton variant="contained" fullWidth onClick={AddUserHandler}>
            Add User
          </PrimaryButton>
        }
        filterOptions={(x) => x}
        options={options}
        getOptionLabel={(opt) => opt.artistName}
        autoComplete
        filterSelectedOptions
        value={value}
        onChange={(event: any, newValue: IValue | null) => {
          // setFieldValue(props.setFieldString, newValue?.artistId);
          if (newValue === null) return;
          props.setData({
            artistId: newValue?.artistId,
            artistName: newValue?.artistName
          });
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        isOptionEqualToValue={React.useCallback(
          (option, val) => option.artistId === value.artistId,
          []
        )}
        // (option: IValue, val: IValue) => option.artistId === val.artistId}
        renderInput={(params) => (
          <FormTextField
            {...params}
            // customlabel="Artist"
            placeholder={props.placeholder || ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        renderOption={(liProps, option) => {
          return (
            <li {...liProps} key={option.artistId}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                </Grid>
                <Grid item xs>
                  <span>{option.artistName}</span>
                  <Typography variant="body2" color="text.secondary">
                    {option.artistId}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
}
