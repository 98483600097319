import { memo, useContext, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { FormContainer } from './FormContainer';
import { Chip, Grid, Stack } from '@mui/material';
import { Login } from '@mui/icons-material';
import UserContext from 'context/UserContext';
import { useSnackbarContext } from 'components/Snackbar/context';
import interestList from '../../SignUp/interests.json';

const InterestForm = (props: any) => {
  const { user } = useContext(UserContext);
  const [selectedInterest, setSelectedInterest] = useState<Record<string, boolean>>({});
  const {
    ToastService: { showToast }
  } = useSnackbarContext();

  const { values, setFieldValue, handleSubmit, validateForm } = useFormikContext<any>();

  const onChipSelect = (item: string) => {
    const newInterestList = { ...selectedInterest };
    if (newInterestList[item] === true) {
      delete newInterestList[item];
    } else {
      newInterestList[item] = true;
    }
    setSelectedInterest({ ...newInterestList });
  };

  useEffect(() => {
    const interests = Object.keys(selectedInterest);
    setFieldValue('interests', interests);
  }, [selectedInterest]);

  useEffect(() => {
    const UserInterests: any = {};
    for (const item of interestList) {
      UserInterests[item] = false;
    }
    setSelectedInterest(UserInterests);
    const interests = Object.keys(UserInterests);
    setFieldValue('interests', interests);
  }, []);

  useEffect(() => {
    const UserInterests: any = {};
    if (user?.interests) {
      for (const item of user?.interests) {
        UserInterests[item] = true;
      }
    }
    setSelectedInterest(UserInterests);
    setFieldValue('interests', UserInterests);
  }, [user]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'interests') {
        message = 'Please select atleast one interest';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <FormContainer name="interests" onSubmit={onSubmit}>
      <Stack direction="column" spacing={6}>
        <Grid container>
          <Grid item xs={12} sm={8} display="flex" flexWrap="wrap" justifyContent="center">
            {interestList.map((item: string, i: number) => (
              <Chip
                key={i}
                label={item}
                className={selectedInterest[item] ? 'selected' : ''}
                onClick={() => onChipSelect(item)}
                sx={{
                  background: '#FFFFFF',
                  boxShadow:
                    '-10px -10px 20px rgba(238, 238, 238, 0.4), 10px 10px 20px rgba(24, 26, 43, 0.15)',
                  mx: 2,
                  my: 1,
                  px: 1,
                  py: 2.5,
                  borderRadius: '10px',
                  '&.selected': {
                    background: '#8ED1FC'
                  },
                  '& .MuiChip-label': {
                    fontSize: '14px',
                    fontWeight: 600
                  }
                }}
              />
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            alignContent="center">
            <PrimaryButton variant="contained" type="submit" endIcon={<Login />}>
              Enter
            </PrimaryButton>
          </Grid>
        </Grid>
      </Stack>
    </FormContainer>
  );
};

export default memo(InterestForm);
