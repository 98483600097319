import { useState, memo, useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FormikInputField } from './CommonTextField';
import { PrimaryButton } from 'components/Buttons/Buttons';
import { Typography, Stack } from '@mui/material';
import UserContext from 'context/UserContext';
import { FormContainer } from './FormContainer';
import { useSnackbarContext } from 'components/Snackbar/context';
import { DatePicker } from '@mui/x-date-pickers';
import OrganisationAutoComplete from '../../../components/AutoComplete/OrganisationAutocomplete';

const MediaCoverageForm = (props: any) => {
  const { user } = useContext(UserContext);
  const {
    ToastService: { showToast }
  } = useSnackbarContext();
  const [selectedOrganisation, setSelectedOrganisation] = useState<any>(null);
  const { values, setFieldError, setFieldValue, handleSubmit, validateForm } =
    useFormikContext<any>();
  const handleDateChange = (date: any, dateText: string, keyboardInput?: string) => {
    let inputDate = '';
    if (keyboardInput) {
      try {
        inputDate = new Date(keyboardInput).toISOString();
      } catch (error) {
        setFieldError(dateText, 'Please enter a valid date in mm/dd/yyyy format');
      }
    }
    if (date && !keyboardInput) {
      inputDate = new Date(date).toISOString();
    }
    setFieldValue(dateText, inputDate);
  };

  const updateFieldValue = async () => {
    const userMediaCoverages: any = user?.mediaCoverages ? [...user.mediaCoverages] : [];
    if (userMediaCoverages.length !== 0 && typeof userMediaCoverages[0].organisationId !== 'string')
      userMediaCoverages.map((mediaCoveragesItem: any) => {
        mediaCoveragesItem.organisationId = mediaCoveragesItem.organisationId._id;
      });
    if (props.submitActionType === 'add') {
      userMediaCoverages.unshift({
        organisationId: '',
        title: '',
        startDate: '',
        description: ''
      });
    } else if (props.submitActionType === 'edit') {
      setSelectedOrganisation(userMediaCoverages[props.index].organisationId);
    }
    setFieldValue('mediaCoverages', userMediaCoverages);
  };

  useEffect(() => {
    updateFieldValue();
  }, []);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const form: any = await validateForm();
    let message = '';
    if (form && Object.keys(form).length === 0) {
      handleSubmit();
    }
    for (const key in form) {
      if (key === 'mediaCoverages') {
        message = 'Please enter your complete details';
      } else {
        message = form[key];
      }
      showToast(true, 'error', message);
      break;
    }
  };

  return (
    <>
      <FormContainer name="mediaCoverages" onSubmit={onSubmit}>
        <Stack direction="column" justifyContent="space-around" alignItems="center">
          <FormikInputField name={`mediaCoverages[${props.index}].title`} placeholder="Title" />
          <OrganisationAutoComplete
            setFieldString={`mediaCoverages[${props.index}].organisationId`}
            selectedOrganisationId={selectedOrganisation}
            source="Media/Magazine"
            placeholder="Name of Media/Magazine organisation"
          />
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{
              marginLeft: '2.5%',
              width: '100%'
            }}>
            <DatePicker
              label="Started in: mm/yyyy"
              disableFuture
              views={['year', 'month']}
              value={
                typeof values.mediaCoverages[props.index] !== 'undefined' &&
                values.mediaCoverages[props.index].startDate
                  ? values.mediaCoverages[props.index].startDate
                  : null
              }
              onChange={(date, input) => {
                handleDateChange(date, `mediaCoverages[${props.index}].startDate`, input);
              }}
              renderInput={(params) => (
                <FormikInputField name={`mediaCoverages[${props.index}].startDate`} {...params} />
              )}
            />
          </Stack>
        </Stack>
        <FormikInputField
          name={`mediaCoverages[${props.index}].description`}
          placeholder="Add Description"
          multiline={true}
          rows={5}
        />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '50px' }}>
          <PrimaryButton type="submit" sx={{ background: '#EEEEEE' }}>
            Submit
          </PrimaryButton>
        </Stack>
      </FormContainer>
    </>
  );
};

export default memo(MediaCoverageForm);
